import React from "react";
import Loader from "../../spinner.gif";

const FormLoading = ({ label = "Please wait" }) => {
	return (
		<div className="w-100 text-center p-5">
			<div className="form-group w-100">
				<img src={Loader} width="64px" height="64px" alt="Loading" />
				<br />
			</div>

			<span className="w-100">{label} ...</span>
		</div>
	);
};

export default FormLoading;
