/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { AuthContent } from "../util/authWrapper";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [loaderStatus, setLoaderStatus] = useState("accounts");

	const [invoiceParams, setCheckoutParams] = useState({
		selectedAccount: null,
		selectedCurrency: "",
		amount: "",
		priority: "crypto",
		paymentDescription: "",
	});
	const [invoiceData, setCheckoutData] = useState(null);

	const [accountOptions, setAccount] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	useEffect(() => {
		loadAccounts();
	}, []);

	const loadAccounts = () => {
		setLoaderShow(true);
		setError(null);

		SendPostRequestWithAuth(appLink.withdrawOptions, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setAccount(response.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const previewCheckoutOptions = (e) => {
		e.preventDefault();

		if (invoiceParams.selectedAccount === null) {
			setError("Please select the receiving cryptocurrency");
			return;
		}

		if (invoiceParams.selectedCurrency === "") {
			setError("Please select the receiving cryptocurrency");
			return;
		}

		if (isNaN(invoiceParams.amount)) {
			setError("Amount should only be digits");
			return;
		}

		if (invoiceParams.priority === "fiat") {
			if (Number(invoiceParams.amount) < 1) {
				setError("Minimum amount payable in FIAT is 1 USD");
				return;
			}
		} else {
			if (Number(invoiceParams.amount) < 0) {
				setError("Amount payable in CRYPTO is not valid.");
				return;
			}
		}

		if (invoiceParams.paymentDescription.length < 5) {
			setError("Payment description must be at least 5 characters");
			return;
		}

		setError(null);
		setLoaderStatus("sending_invoice");
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.invoiceEndpoint, invoiceParams, accessToken)
			.then((response) => {
				setLoaderShow(false);
				if (response.data.success) {
					setCheckoutData(response.data.invoice);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} show={false}>
				<div className="row justify-content-center mt-2">
					<div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center mt-5">
						<h1 className="mb-3 mt-5">Checkout Form</h1>

						<p className="mb-1 text-muted">Checkout creates a secure, hosted payment page that lets you collect payments quickly from customers.</p>
					</div>

					<div className="col-12 col-lg-10 col-xl-8">
						{error !== null && (
							<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
								{error}
								<button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						)}

						{!loaderShow ? (
							<React.Fragment>
								{invoiceData === null ? (
									<form onSubmit={previewCheckoutOptions}>
										<hr className="my-5" />
										<div className="row">
											<div className="col-12 col-md-4">
												<div className="form-group">
													<label>Cryptocurrency</label>

													<select
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															if (e.target.value.length !== 0) {
																setCheckoutParams({
																	...invoiceParams,
																	selectedAccount: accountOptions.accounts.filter((account) => account.currencySymbol === e.target.value)[0],
																	selectedCurrency: e.target.value,
																});
															} else {
																setCheckoutParams({
																	...invoiceParams,
																	selectedAccount: null,
																	selectedCurrency: e.target.value,
																});
															}
														}}
													>
														{invoiceParams.selectedAccount === null && <option value={""}>Select Cryptocurrency</option>}
														{accountOptions !== null &&
															accountOptions.accounts.map((account, index) => (
																<option key={index} value={account.currencySymbol}>
																	{account.currencyName}
																</option>
															))}
													</select>
												</div>
											</div>

											<div className="col-12 col-md-3">
												<div className="form-group">
													<label>Payment Mode</label>

													<select
														className="form-control"
														required={true}
														value={invoiceParams.priority}
														onChange={(e) => {
															e.preventDefault();

															setCheckoutParams({
																...invoiceParams,
																priority: e.target.value,
															});
														}}
													>
														<option value={"fiat"}>FIAT</option>
														<option value={"crypto"}>CRYPTO</option>
													</select>
												</div>
											</div>

											<div className="col-12 col-md-5">
												{invoiceParams.priority === "fiat" ? (
													<div className="form-group">
														<label>Amount ( USD )</label>

														<div className="input-group mb-3">
															<div className="input-group-prepend">
																<span className="input-group-text" id="basic-addon1">
																	USD
																</span>
															</div>
															<input
																type="number"
																className="form-control"
																placeholder="Amount to be paid in USD"
																aria-label="100"
																min={3}
																aria-describedby="basic-addon1"
																required={true}
																onChange={(e) => {
																	e.preventDefault();
																	setCheckoutParams({
																		...invoiceParams,
																		amount: e.target.value,
																	});
																}}
															/>
														</div>
													</div>
												) : (
													<div className="form-group">
														<label>Amount</label>

														<input
															type="text"
															className="form-control"
															placeholder="Amount"
															aria-label="0.05645"
															aria-describedby="basic-addon1"
															required={true}
															onChange={(e) => {
																e.preventDefault();
																setCheckoutParams({
																	...invoiceParams,
																	amount: e.target.value,
																});
															}}
														/>
													</div>
												)}
											</div>

											<div className="col-12">
												{invoiceParams.priority === "fiat" ? (
													<small className="form-text text-muted">USD amount will be converted to selected cryptocurrency equivalent when the user is paying.</small>
												) : (
													<small className="form-text text-muted">User will pay the cryptocurrency amount stated.</small>
												)}
											</div>

											<div className="col-12 col-md-6 mt-2">
												{/* Email address */}
												<div className="form-group">
													{/* Label */}
													<label className="mb-1">Title</label>
													{/* Form text */}
													<small className="form-text text-muted">The name of the item or service.</small>
													{/* Input */}
													<input
														type="text"
														className="form-control"
														placeholder="Name of the recipient"
														aria-label="JohnDoe"
														aria-describedby="basic-addon1"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setCheckoutParams({
																...invoiceParams,
																title: e.target.value,
															});
														}}
													/>
												</div>
											</div>

											<div className="col-12 col-md-6 mt-2">
												{/* Email address */}
												<div className="form-group">
													{/* Label */}
													<label className="mb-1">Photo URL of Item (Optional)</label>
													{/* Form text */}
													<small className="form-text text-muted">A image url of the item/service being purchased.</small>
													{/* Input */}
													<input
														type="email"
														className="form-control"
														placeholder="Email address"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setCheckoutParams({
																...invoiceParams,
																email: e.target.value,
															});
														}}
													/>
												</div>
											</div>

											<div className="col-12">
												{/* Email address */}
												<div className="form-group">
													{/* Label */}
													<label className="mb-1">Description of Checkout Item</label>
													{/* Form text */}
													<small className="form-text text-muted">A more detailed description of the item / service.</small>
													{/* Input */}
													<textarea
														className="form-control"
														placeholder="Item description"
														rows="5"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setCheckoutParams({
																...invoiceParams,
																paymentDescription: e.target.value,
															});
														}}
													></textarea>
												</div>
											</div>
										</div>
										{/* / .row */}
										{/* Button */}
										<div className="col-12 text-center">
											<button className="btn btn-primary w-50">Send Checkout</button>
										</div>
									</form>
								) : (
									<React.Fragment>
										<div className="card mt-5 text-center">
											<div className="card-body ">
												<i className="fe fe-gift text-success" style={{ fontSize: 90 }} />

												<div className="h4 text-uppercase text-center text-success mt-2 mb-5">INVOICE SENT SUCCESSFULLY</div>

												<div className="mb-3">
													<ul className="list-group list-group-flush">
														<li className="list-group-item d-flex align-items-center justify-content-between px-0">
															<small>ID</small>
															<a href={invoiceData.url} target="_blank" rel="noreferrer noopener">
																{invoiceData.id}
															</a>
														</li>
														<li className="list-group-item d-flex align-items-center justify-content-between px-0">
															<small>Amount</small>
															<small>{invoiceData.amountUSD}</small>
														</li>
														<li className="list-group-item d-flex align-items-center justify-content-between px-0">
															<small>Email</small>
															<small>{invoiceData.email}</small>
														</li>
														<li className="list-group-item d-flex align-items-center justify-content-between px-0">
															<small>Date</small>
															<small>{invoiceData.date}</small>
														</li>
													</ul>
												</div>
												<br />
												<small
													className="text-success"
													style={{ cursor: "pointer" }}
													onClick={(e) => {
														setCheckoutParams({
															...invoiceParams,
															selectedAccount: null,
															selectedCurrency: "",
															fullName: "",
															amount: "",
															email: "",
															priority: "crypto",
															paymentDescription: "",
														});
														setCheckoutData(null);
													}}
												>
													Done
												</small>
											</div>
										</div>
									</React.Fragment>
								)}
							</React.Fragment>
						) : (
							<FormLoading label={loaderStatus === "accounts" ? "Fetching accounts" : loaderStatus === "sending_invoice" ? `Preparing a hot Checkout meal for ${invoiceParams.fullName}` : "Sending out invoice"} />
						)}
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
