/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormLoading from "../loader/form_loading";
import { SendPostRequestWithAuth } from "../util/request";
import { useStoreState } from "easy-peasy";
import { AuthContent } from "../util/authWrapper";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);

	const { username } = useStoreState((state) => state.account);
	const userPaymentLink = appLink.paymentURL.replace("{x}", username);
	const [userAddresses, setUserAddresses] = useState(null);

	const [paymentLinkCopied, setPaymentLinkCopied] = useState(false);
	const [addressCopied, setAddressCopied] = useState(false);
	const [currencyCopied, setCurrencyCopied] = useState(false);

	const { accessToken } = useStoreState((state) => state.account);

	const loadAddresses = () => {
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.getAllAddresses, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setUserAddresses(response.data.addresses);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		loadAddresses();
	}, []);

	const copyTxt = (currency) => {
		setCurrencyCopied(currency);
		setAddressCopied(false);
		setPaymentLinkCopied(true);
	};

	const copyAddress = (currency) => {
		setCurrencyCopied(currency);
		setPaymentLinkCopied(false);
		setAddressCopied(true);
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Address"} bigText={"Payment Links"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="card card-bleed shadow-light-lg">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										{/* Heading */}
										<h4 className="mb-0">Payment Links</h4>
									</div>
								</div>
							</div>
							<div className="card-body">
								{!loaderShow ? (
									<React.Fragment>
										{userAddresses !== null &&
											userAddresses.map((address, index) => (
												<div className="col-12" key={index}>
													<div className="row">
														<div className="col-12 col-lg-4">
															<div className="card p-4 justify-content-center align-items-center">
																<div className="col-12 text-center">
																	<small className="text-dark" style={{ fontSize: "0.6rem" }}>
																		{address.addressCurrencyName}
																	</small>
																</div>
																<QRCode size={128} value={address.address} />

																<div className="col-12 text-center">
																	<small className="text-dark" style={{ fontSize: "0.6rem" }}>
																		{address.address}
																	</small>
																</div>
															</div>
														</div>
														<div className="col-12 col-lg-8">
															<div className="row">
																<div className="col-12">
																	<small className="form-text text-muted">{address.addressCurrencyName} Payment Link </small>
																	<div className="input-group mb-3">
																		<input type="text" className="form-control" value={`${userPaymentLink}?address=${address.addressCurrencyCode.toLowerCase()}`} />
																		<div className="input-group-append">
																			<CopyToClipboard text={`${userPaymentLink}?address=${address.addressCurrencyCode.toLowerCase()}`} onCopy={() => copyTxt(address.addressCurrencyCode)}>
																				<button
																					onClick={(e) => {
																						e.preventDefault();
																					}}
																					className="btn btn-outline-primary"
																					type="button"
																					id="button-addon2"
																				>
																					{paymentLinkCopied && currencyCopied === address.addressCurrencyCode ? "Copied" : "Copy"}
																				</button>
																			</CopyToClipboard>
																		</div>
																	</div>
																	<small className="mt-3">Share this link with anyone who wants to make payment to you</small>
																</div>
															</div>

															<div className="row mt-3">
																<div className="col-12">
																	<small className="form-text text-muted">{address.addressCurrencyName} address</small>
																	<div className="input-group mb-3">
																		<input type="text" className="form-control" value={address.address} />
																		<div className="input-group-append">
																			<CopyToClipboard text={address.address} onCopy={() => copyAddress(address.addressCurrencyCode)}>
																				<button
																					onClick={(e) => {
																						e.preventDefault();
																					}}
																					className="btn btn-outline-primary"
																					type="button"
																					id="button-addon2"
																				>
																					{addressCopied && currencyCopied === address.addressCurrencyCode ? "Copied" : "Copy"}
																				</button>
																			</CopyToClipboard>
																		</div>
																	</div>
																	<small className="mt-3">Share this {address.addressCurrencyName.toLowerCase()} address with anyone who wants to make payment to you</small>
																</div>
															</div>
														</div>
													</div>
												</div>
											))}
									</React.Fragment>
								) : (
									<FormLoading />
								)}
							</div>
						</div>
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
