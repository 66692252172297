/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { AuthContent } from "../util/authWrapper";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [passwordChangeLoader, setPasswordChangeLoader] = useState(false);
	const [error, setError] = useState(null);
	const [mfaStatus, setMfaStatus] = useState(null);
	const [userMFATokenKey, setUserMfaTokenKey] = useState("");
	const [mfaQrCode, setMFAQrCode] = useState(null);
	const [mfaPlainCode, setMFAPlainCode] = useState(null);
	const [disableMFA, setDisableMFA] = useState(false);
	const [passwordChangeError, setPasswordChangeError] = useState(null);

	const [passwordUpdated, setPasswordUpdated] = useState(false);
	const [passwordParams, setPasswordParams] = useState({
		confirmPassword: "",
		newPassword: "",
		currentPassword: "",
	});
	const { accessToken } = useStoreState((state) => state.account);

	useEffect(() => {
		loadMFAStatus();
	}, []);

	const loadMFAStatus = () => {
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.getMFAStatus, {}, accessToken)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setMfaStatus(response.data.mfa);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const enableMFAStatus = () => {
		setLoaderShow(true);

		SendPostRequestWithAuth(
			appLink.updateMFAStatus,
			{
				status: "enable",
			},
			accessToken
		)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setMfaStatus(response.data.mfa);
					setMFAQrCode(response.data.otpCode);
					setMFAPlainCode(response.data.code);
					setUserMfaTokenKey("");
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const disableMFAStatus = (e) => {
		e.preventDefault();
		setLoaderShow(true);

		SendPostRequestWithAuth(
			appLink.updateMFAStatus,
			{
				status: "disable",
				tokenKey: userMFATokenKey,
			},
			accessToken
		)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setMfaStatus(response.data.mfa);
					setMFAQrCode(null);
					setDisableMFA(false);
					setUserMfaTokenKey("");
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const verifyMFAToken = (e) => {
		e.preventDefault();
		setLoaderShow(true);

		SendPostRequestWithAuth(
			appLink.verifyMFATokenKey,
			{
				tokenKey: userMFATokenKey,
			},
			accessToken
		)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setMfaStatus(response.data.mfa);
					setMFAQrCode(null);
					setUserMfaTokenKey("");
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const updatePassword = () => {
		setPasswordChangeError(null);

		setPasswordChangeLoader(true);
		SendPostRequestWithAuth(appLink.updatePasswordEndpoint, { ...passwordParams }, accessToken)
			.then(function (response) {
				setPasswordChangeLoader(false);
				setPasswordUpdated(true);
				if (response.data.success) {
					setPasswordParams({
						confirmPassword: "",
						newPassword: "",
						currentPassword: "",
					});
				} else {
					setPasswordChangeError(response.data.message);
				}
			})
			.catch((error) => {
				setPasswordChangeLoader(false);

				setPasswordChangeError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Account"} bigText={"Security"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="card card-bleed shadow-light-lg">
							<div className="card-header">
								<h4 className="mb-0">Multi-Factor Authentication</h4>
							</div>
							<div className="card-body">
								{!loaderShow ? (
									mfaStatus ? (
										<React.Fragment>
											<div className="row">
												<div className="col-12 col-md-12">
													<p>
														Your account is <span className="text-success">secured</span> with multi-factor authentication.
													</p>
												</div>
												{disableMFA && (
													<form onSubmit={disableMFAStatus}>
														<div className="col-12 col-md-12">
															<label htmlFor="name">
																1. To disable Multi Factor Authentication. Open your <span className="text-primary">Authenticator</span> app.
															</label>
															<br />
															<label htmlFor="name">2. Enter OTP Code from the Authenticator App</label>
															<div className="form-group">
																<input className="form-control" value={userMFATokenKey} onChange={(e) => setUserMfaTokenKey(e.target.value)} type="text" placeholder="Enter code from app" />
															</div>
														</div>

														<div className="col-12 col-md-auto mt-2">
															<button className="btn btn-primary" type="submit">
																Verify &amp; Disable Authentication
															</button>
															<button
																className="btn btn-danger ml-2"
																type="button"
																onClick={(e) => {
																	e.preventDefault();
																	setDisableMFA(false);
																}}
															>
																Cancel
															</button>
														</div>
													</form>
												)}

												{!disableMFA && (
													<div className="col-12 col-md-auto mt-2">
														<button className="btn btn-block btn-primary" type="button" onClick={() => setDisableMFA(true)}>
															Disable Multi-Factor Authentication
														</button>
													</div>
												)}
											</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div>
												<div className="row">
													<div className="col-12 col-md-12">
														<p>
															Your account is <span className="text-danger">unprotected</span>. Enable multi-factor authentication to add an extra layer of security to your account.
														</p>
													</div>

													{mfaQrCode !== null && (
														<React.Fragment>
															<div className="col-12">
																<div className="row">
																	<div className="col-12 col-md-5 ">
																		<div className="card p-5 justify-content-center align-items-center">
																			<QRCode value={mfaQrCode} style={{ borderRadius: "100px" }} />

																			<div className="input-group mt-5">
																				<div className="input-group-prepend">
																					<span className="input-group-text" id="basic-addon1">
																						TOKEN
																					</span>
																				</div>
																				<input type="text" value={mfaPlainCode} style={{ borderRightColor: "transparent" }} readonly={true} className="form-control text-muted" aria-describedby="basic-addon1" />
																				<button
																					className="btn btn-outline-secondary"
																					style={{
																						borderTopLeftRadius: 0,
																						borderBottomLeftRadius: 0,
																					}}
																				>
																					Copy
																				</button>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-md-5">
																		<div className="card p-5">
																			<form onSubmit={verifyMFAToken}>
																				<div className="col-12 col-md-12">
																					<label htmlFor="name">
																						1. Install an authenticator app :{" "}
																						<a href="https://authy.com/features/" target="_blank" rel="noopener noreferrer" className="text-primary">
																							Authy
																						</a>
																						,{" "}
																						<a href="https://www.microsoft.com/en-us/account/authenticator" target="_blank" rel="noopener noreferrer" className="text-primary">
																							Microsoft Authenticator
																						</a>
																						,{" "}
																						<a href="https://1password.com/" target="_blank" rel="noopener noreferrer" className="text-primary">
																							1Password
																						</a>
																						, or any compatible client from your App Store
																					</label>
																					<label htmlFor="name">
																						2. Scan the QR Image with your authenticator app or copy the TOKEN key into your authenticator app if the app not support QR Code scanning.
																					</label>
																					<label htmlFor="name">3. Enter the OTP code that is generated from your authenticator app into the input below</label>
																					<div className="form-group">
																						<input className="form-control" value={userMFATokenKey} onChange={(e) => setUserMfaTokenKey(e.target.value)} type="text" placeholder="Enter code from app" />
																					</div>
																				</div>

																				<div className="col-12 col-md-auto mt-2">
																					<button className="btn btn-block btn-primary" type="submit">
																						Verify &amp; Enable Authentication
																					</button>
																				</div>
																			</form>
																		</div>
																	</div>

																	<div className="col-12">
																		<h4>
																			Why we do not recommend <span className="text-primary">Google Authenticator</span> as your authentiction app ?
																		</h4>
																		<p>
																			Google Authenticator is well known for it's simplistic approach to offering multi factor authentication. Unfortunately (at the moment), it does not offer backup or secondary
																			access for every user should you lose the device that you installed the Google Authenticator app on. The recommended clients listed above provide backups to your
																			authentication codes should you lose the device holding your authentication codes. However, you are free to secure your account with any authenticator app of your choice
																			including Google Authenticator.
																		</p>
																	</div>
																</div>
															</div>
														</React.Fragment>
													)}

													{mfaQrCode === null && (
														<div className="col-12 col-md-auto mt-2">
															<button className="btn btn-block btn-primary" type="button" onClick={() => enableMFAStatus()}>
																Enable Multi-Factor Authentication
															</button>
														</div>
													)}
												</div>
											</div>
										</React.Fragment>
									)
								) : (
									<FormLoading />
								)}
							</div>
						</div>
					</div>

					{passwordChangeError !== null && (
						<div className="col-12">
							<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
								{passwordChangeError}
								<button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					)}

					<div className="col-12">
						<div className="row">
							<div className="col-12 col-md-6 order-md-2">
								<div className="card bg-light border ms-md-4">
									<div className="card-body">
										<p className="mb-2">Password requirements</p>

										<p className="small text-muted mb-2">To create a new password, you have to meet all of the following requirements:</p>

										<ul className="small text-muted ps-4 mb-0">
											<li>Minimum 8 character</li>
											<li>At least one special character</li>
											<li>At least one number</li>
											<li>Can’t be the same as a previous password</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6">
								<div className="card card-bleed shadow-light-lg">
									{!passwordChangeLoader ? (
										<React.Fragment>
											<div className="card-header">
												<h4 className="mb-0">
													Change Password
													{passwordUpdated && (
														<small className="ml-2 text-success">
															<i className="fe fe-check mr-2" />
															Password changed successfully
														</small>
													)}
												</h4>
											</div>
											<div className="card-body">
												<form onSubmit={updatePassword}>
													<div className="row">
														<div className="col-12 col-md-12">
															<div className="form-group">
																<label htmlFor="name">Current Password</label>
																<input
																	className="form-control"
																	type="password"
																	value={passwordParams.currentPassword}
																	onChange={(e) =>
																		setPasswordParams({
																			...passwordParams,
																			currentPassword: e.target.value,
																		})
																	}
																	placeholder="Current Password"
																	minLength={6}
																	required={true}
																/>
															</div>
														</div>
														<div className="col-12 col-md-12">
															<div className="form-group">
																<label htmlFor="name">New Password</label>
																<input
																	className="form-control"
																	type="password"
																	placeholder="New Password"
																	value={passwordParams.newPassword}
																	onChange={(e) =>
																		setPasswordParams({
																			...passwordParams,
																			newPassword: e.target.value,
																		})
																	}
																	minLength={6}
																	required={true}
																/>
															</div>
														</div>

														<div className="col-12 col-md-12">
															<div className="form-group">
																<label htmlFor="name">Confirm Password</label>
																<input
																	className="form-control"
																	type="password"
																	placeholder="Confirm Password"
																	value={passwordParams.confirmPassword}
																	onChange={(e) =>
																		setPasswordParams({
																			...passwordParams,
																			confirmPassword: e.target.value,
																		})
																	}
																	minLength={6}
																	required={true}
																/>
															</div>
														</div>
														<div className="col-12 col-md-auto mt-2">
															<button className="btn btn-block btn-primary" type="submit">
																Update New Password
															</button>
														</div>
													</div>
												</form>
											</div>
										</React.Fragment>
									) : (
										<FormLoading />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
