/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";
import { useStoreActions } from "easy-peasy";
import { Link } from "react-router-dom";

export default function AuthSideBar({ appLink }) {
	const { accessToken } = useStoreState((state) => state.account);
	const logout = useStoreActions((actions) => actions.logout);
	const [loggingOut, setLoggingOut] = useState(false);

	const signOut = () => {
		setLoggingOut(true);

		SendPostRequestWithAuth(appLink.signOutEndpoint, {}, accessToken)
			.then((response) => {
				setLoggingOut(false);

				if (response.data.success) {
					logout();
				}
			})
			.catch((error) => {
				setLoggingOut(false);
			});
	};

	return (
		<React.Fragment>
			<nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
				<div className="container-fluid">
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon" />
					</button>
					<a className="navbar-brand" href="/dashboard">
						<img src={`/Kollet-Dashboard.svg`} className="navbar-brand-img mx-auto" alt="..." style={{}} />
					</a>
					<div className="collapse navbar-collapse" id="sidebarCollapse">
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link to={appLink.dashboard} className="nav-link">
									<i className="fe fe-home" /> Dashboard
								</Link>
							</li>

							{/* <li className="nav-item">
								<Link to={appLink.paymentLinks} className="nav-link">
									<i className="fe fe-credit-card" /> Payment Link
								</Link>
							</li>

							<li className="nav-item">
								<Link to={appLink.payments} className="nav-link">
									<i className="fe fe-globe" /> Payments
								</Link>
							</li> */}

							<li className="nav-item">
								<a className="nav-link" href="#sidebarPayments" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPayments">
									<i className="fe fe-globe" /> Payments
								</a>
								<div className="collapse" id="sidebarPayments">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link to={appLink.paymentLinks} className="nav-link">
												Receive Payments
											</Link>
										</li>
										<li className="nav-item">
											<Link to={appLink.payments} className="nav-link">
												List Payments
											</Link>
										</li>
									</ul>
								</div>
							</li>

							{/*<li className="nav-item">
								<a className="nav-link" href="#sidebarCheckout" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarCheckout">
									<i className="fe fe-shopping-cart" /> Checkout
								</a>
								<div className="collapse" id="sidebarCheckout">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link to={appLink.checkOutCreate} className="nav-link">
												Create Checkout
											</Link>
										</li>
										<li className="nav-item">
											<Link to={appLink.invoices} className="nav-link">
												List Checkouts
											</Link>
										</li>
									</ul>
								</div>
	</li>*/}

							<li className="nav-item">
								<a className="nav-link" href="#sidebarTransfers" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarTransfers">
									<i className="fe fe-external-link" /> Withdrawal
								</a>
								<div className="collapse" id="sidebarTransfers">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link to={appLink.withdraw} className="nav-link">
												Make Withdrawal
											</Link>
										</li>
										<li className="nav-item">
											<Link to={appLink.transfers} className="nav-link">
												List Withdrawals
											</Link>
										</li>
									</ul>
								</div>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="#sidebarInvoice" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarInvoice">
									<i className="fe fe-clipboard" /> Invoice
								</a>
								<div className="collapse" id="sidebarInvoice">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link to={appLink.invoice} className="nav-link">
												Create Invoice
											</Link>
										</li>
										<li className="nav-item">
											<Link to={appLink.invoices} className="nav-link">
												List Invoices
											</Link>
										</li>
									</ul>
								</div>
							</li>

							<li className="nav-item">
								<Link to={appLink.report} className="nav-link">
									<i className="fe fe-database" /> Reports <small className="ml-2 badge badge-primary">NEW 🔥</small>
								</Link>
							</li>

							{/*<li className="nav-item">
								<a className="nav-link" href="#businessTools" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="businessTools">
									<i className="fe fe-command" /> Business Tools
								</a>
								<div className="collapse" id="businessTools">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link to={appLink.products} className="nav-link">
												Products
											</Link>
										</li>
										<li className="nav-item">
											<a className="nav-link" target="_blank" rel="noreferrer noopener" href={appLink.pos}>
												Point of Sales
											</a>
										</li>
									</ul>
								</div>
						</li>*/}
						</ul>
						{/* Divider */}
						<hr className="navbar-divider my-3" />
						{/* Heading */}
						<h6 className="navbar-heading">Account</h6>
						{/* Navigation */}
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link" to={appLink.brandProfile}>
									<i className="fe fe-user" /> Brand
								</Link>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={appLink.currencies}>
									<i className="fe fe-sliders" /> Cryptocurrencies
								</Link>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={appLink.security}>
									<i className="fe fe-shield" /> Security
								</Link>
							</li>
						</ul>

						<hr className="navbar-divider my-3" />

						<h6 className="navbar-heading">Developer</h6>
						{/* Navigation */}
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link" to={appLink.developerTokens}>
									<i className="fe fe-link-2" /> Integration
								</Link>
							</li>

							<li className="nav-item">
								<a className="nav-link" target="_blank" rel="noreferrer noopener" href={appLink.developerDocs}>
									<i className="fe fe-box" /> Documentation
								</a>
							</li>
						</ul>

						{/* Push content down */}
						<div className="mt-auto" />
						{/* User (md) */}
						<div className="navbar-user d-flex" id="sidebarUser">
							{/* Icon */}
							{/*<a href="#sidebarModalActivity" className="navbar-user-link" data-toggle="modal">
								<span className="icon">
									<i className="fe fe-bell" />
								</span>
						</a>*/}
							{loggingOut ? (
								<FormLoading label="Signing out" />
							) : (
								<a
									href="@"
									onClick={(e) => {
										e.preventDefault();
										signOut();
									}}
									className="navbar-user-link"
								>
									<span className="icon">
										<i className="fe fe-power" /> Sign Out
									</span>
								</a>
							)}
						</div>
					</div>{" "}
					{/* / .navbar-collapse */}
				</div>
			</nav>
		</React.Fragment>
	);
}
