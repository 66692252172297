/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContent } from "../util/authWrapper";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [loaderTitle, setLoaderTitle] = useState("Please wait");
	const [availableCurrencies, setAvailableCurrencies] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	useEffect(() => {
		loadCurrencies();
	}, []);

	const loadCurrencies = () => {
		setError(null);
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.getAllCurrencies, {}, accessToken)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setAvailableCurrencies(response.data.available);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const updateCurrencyStatus = (e, selectedCurrency) => {
		e.preventDefault();

		setError(null);
		setLoaderTitle(`Enabling ${selectedCurrency.toUpperCase()} currency`);
		setLoaderShow(true);

		SendPostRequestWithAuth(
			appLink.enableCurrency,
			{
				selectedCurrency,
			},
			accessToken
		)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					loadCurrencies();
				} else {
					setError(response.data.message);
				}

				setLoaderTitle("Please wait");
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");

				setLoaderTitle("Please wait");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Account"} bigText={"Cryptocurrencies"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="card card-bleed shadow-light-lg">
							{!loaderShow ? (
								<React.Fragment>
									<div className="card-header">
										<h4 className="mb-0">List of all accepted cryptocurrencies</h4>
									</div>
									<div className="card-body">
										<div className="list-group list-group-flush my-n3">
											{availableCurrencies !== null &&
												availableCurrencies.map((currency) => (
													<div className="list-group-item">
														<div className="row align-items-center">
															<div className="col-1 text-center">
																<img src={`${currency.currencyImage}`} style={{ width: "50px", height: "50px" }} alt={currency.currencyName} />
															</div>
															<div className="col">
																{/* Heading */}
																<h4 className="mb-1">
																	{currency.currencyName}{" "}
																	<i className="fe fe-info text-muted ms-1" data-bs-toggle="tooltip" data-title="We use the the phone number you provide in General" data-bs-original-title title />
																</h4>
																{/* Text */}
																<small className="text-muted">{currency.currencyDescription}</small>
																<br />

																{currency.enabled && (
																	<>
																		<small className="text-dark">
																			<Link to={appLink.paymentLinks}>Receive</Link>
																			<Link className="ml-2" to={appLink.withdraw}>
																				Withdraw
																			</Link>
																		</small>
																		<br />
																	</>
																)}

																<small className="text-dark">Currency Code: {currency.currencyCode}</small>
																<br />
																<small className="text-dark">Network Confirmations: {currency.currencyConfirmation}</small>
																<br />
																<small className="text-dark">
																	More Information:{" "}
																	<a href={currency.currencyWebsite} target="_blank" rel="noreferrer noopener">
																		{currency.currencyWebsite}
																	</a>
																</small>
																<br />
															</div>
															<div className="col-auto">
																{/* Button */}
																{!currency.enabled ? (
																	<button className="btn btn-sm btn-primary" onClick={(e) => updateCurrencyStatus(e, currency.currencyCode)}>
																		Enable
																	</button>
																) : (
																	<span className="text-success">Enabled</span>
																)}
															</div>
														</div>{" "}
														{/* / .row */}
													</div>
												))}
										</div>
									</div>
								</React.Fragment>
							) : (
								<FormLoading label={loaderTitle} />
							)}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card card-bleed shadow-light-lg">
							{!loaderShow ? (
								<React.Fragment>
									<div className="card-header">
										<h4 className="mb-0">Independent Node as a Server</h4>
									</div>
									<div className="card-body">
										<div className="list-group list-group-flush my-n3">
											<div className="list-group-item">
												<div className="row align-items-center">
													<div className="col-1 text-center">
														<img src={`https://edge-kollet.fra1.digitaloceanspaces.com/kollet/tron.png`} style={{ width: "50px", height: "50px" }} alt="tronix" />
													</div>
													<div className="col">
														{/* Heading */}
														<h4 className="mb-1">
															TRON <i className="fe fe-info text-muted ms-1" data-bs-toggle="tooltip" data-title="We use the the phone number you provide in General" data-bs-original-title title />
														</h4>
														{/* Text */}
														<small className="text-muted">
															TRON is a decentralized, open-source blockchain-based operating system with smart contract functionality, proof-of-stake principles as its consensus algorithm and a cryptocurrency native to
															the system, known as Tronix.
														</small>
														<br />

														<small className="text-dark">Native Currency: TRX</small>
														<br />
														<small className="text-dark">Network Confirmation Time: Under 2 mins</small>
														<br />
														<small className="text-dark">Currencies Supported Under TRON: TRX, USDT, USDC, TUSD.</small>
														<br />
														<small className="text-dark">
															More Information:{" "}
															<a href={"https://tron.network/"} target="_blank" rel="noreferrer noopener">
																TRON Network
															</a>
														</small>
														<br />
													</div>
													<div className="col-auto">
														<a href="mailto:support@kollet.io" className="btn btn-sm btn-primary">
															Contact Support for Activation
														</a>
													</div>
												</div>{" "}
											</div>
										</div>
									</div>
								</React.Fragment>
							) : (
								<FormLoading label={loaderTitle} />
							)}
						</div>
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
