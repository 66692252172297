/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import countries from "../util/countries.js";
import FormLoading from "../loader/form_loading";
import VerificationOTP from "../../verification-otp-web.svg";
import { useStoreActions, useStoreState } from "easy-peasy";
import { SendPostRequest } from "../util/request";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const ComponentBody = ({ appLink, ...props }) => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setPasswordShow] = useState("");
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [currentStep, setCurrentStep] = useState(1);
	const [emailVerificationCode, setEmailVerificationCode] = useState(1);
	const [verificationToken, setVerificationToken] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);

	const signUpState = useStoreState((state) => state.signUpState);
	const authenticateUser = useStoreActions((actions) => actions.authenticateUser);
	const setSignUpState = useStoreActions((actions) => actions.setSignUpState);
	const resetSignUpState = useStoreActions((actions) => actions.resetSignUpState);
	const logout = useStoreActions((actions) => actions.logout);

	const passwordValidator = (key) => key.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,25}$/);
	const emailValidator = (email) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

	useEffect(() => {
		document.body.classList.add("bg-auth");

		return () => {
			document.body.classList.remove("bg-auth");
		};
	}, []);

	useEffect(() => {
		if (signUpState === null) {
			setCurrentStep(1);
		} else {
			setEmail(signUpState.email);
			setCurrentStep(2);
		}
	}, [signUpState]);

	const processForm = (e) => {
		e.preventDefault();

		if (!emailValidator(email)) {
			setError("Email is not a valid email address");
			return;
		}

		let usernameReg = !/[^a-z\d]/i.test(username);

		if (!usernameReg) {
			setError("Only alpha-numeric characters are allowed for your username");
			return;
		}

		if (username.length < 6 || username.length > 25) {
			setError("The length of your username should be between 6 and 25 characters");
			return;
		}

		if (!passwordValidator(password)) {
			setError("Password should be within 6 to 25 characters and must contain a lowercase, uppercase, number and a special character");
			return;
		}

		if (selectedCountry.countryCode === "") {
			setError("Please select a country");
			return;
		}

		if (verificationToken === null) {
			setError("Check the reCaptcha box for verification");
			return;
		}

		setError(null);
		setLoaderShow(true);
		SendPostRequest(appLink.createAccountEndpoint, {
			username: username,
			email: email,
			password: password,
			reCaptchaToken: verificationToken,
			...selectedCountry,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setSignUpState(response.data.email);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const requestVerification = (e) => {
		e.preventDefault();

		setError(null);
		setLoaderShow(true);

		SendPostRequest(appLink.emailVerificationEndpont, {
			username: username,
			otpCode: emailVerificationCode,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					authenticateUser(response.data.account);
					resetSignUpState();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="col-12 col-md-6 col-xl-6 my-2 bg-white p-5" style={{ borderRadius: 10 }}>
				<h1 className="display-4 text-center">Create an account</h1>

				<p className="text-muted text-center mb-5">Enjoy secure and seamless cryptocurrency payments</p>

				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						{currentStep === 1 && (
							<form className="pl-5 pr-5 pb-2" onSubmit={processForm}>
								<div className="form-group">
									<label>Username</label>
									<input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value.trim())} placeholder="Account Username" required={true} />
								</div>

								<div className="form-group">
									<label>Email Address</label>

									<input type="email" className="form-control" value={email} placeholder="user@business.com" onChange={(e) => setEmail(e.target.value.trim())} required={true} />
								</div>

								<div className="form-group">
									<div className="row">
										<div className="col">
											<label>Password</label>
										</div>
									</div>

									<div className="input-group input-group-merge">
										<input
											type={showPassword ? "text" : "password"}
											value={password}
											onChange={(e) => setPassword(e.target.value.trim())}
											className="form-control form-control-appended"
											placeholder="Enter your password"
											required={true}
										/>

										<div className="input-group-append">
											<span className="input-group-text" onClick={(e) => setPasswordShow(!showPassword)} style={{ cursor: "pointer" }}>
												<i className={`fe ${showPassword ? "fe-eye-off" : "fe-eye"}`} />
											</span>
										</div>
									</div>
								</div>

								<div className="form-group">
									<div className="row">
										<div className="col">
											<label>Country</label>
										</div>
									</div>
									<select className="form-control" onChange={(e) => setSelectedCountry(countries[e.target.value])}>
										<option value="">Select Country</option>
										{countries.map((country, index) => (
											<option value={index} key={index}>
												{country.countryName}
											</option>
										))}
									</select>
								</div>

								<div className="form-group">
									<HCaptcha sitekey={"4edc6a3c-7da7-4840-ab85-ae98b6aff4d4"} onVerify={(token) => setVerificationToken(token)} />
								</div>

								<button className="btn btn-lg btn-block btn-primary mb-3">Create account</button>

								<div className="text-center">
									<small className="text-muted text-center">
										Already have an account? <Link to={appLink.signIn}>Log In</Link>.
									</small>
								</div>
							</form>
						)}

						{currentStep === 2 && (
							<form className="pl-5 pr-5 pb-5" onSubmit={requestVerification}>
								<div className="form-group text-center">
									<img src={VerificationOTP} width="300px" height="300px" alt="otp" />
									<br />
									<small>
										We've sent a verification code to your email to
										<br />
										{signUpState.email}
									</small>
								</div>

								<div className="form-group">
									<label>Verification Code</label>

									<input type="text" className="form-control" onChange={(e) => setEmailVerificationCode(e.target.value.trim())} placeholder="OTP Code" required={true} />
								</div>

								<button className="btn btn-lg btn-block btn-primary mb-3">Complete Registration</button>

								<small
									className="text-danger"
									style={{ cursor: "pointer" }}
									onClick={(e) => {
										e.preventDefault();
										logout();
										setCurrentStep(1);
										setError(null);
									}}
								>
									Cancel Registration
								</small>
							</form>
						)}
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default ComponentBody;
