/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { AuthContent } from "../util/authWrapper";
import React, { useEffect, useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [selectedCurrency, setSelectedCurrency] = useState("btc");
	const [availableCryptos, setAvailableCryptos] = useState(null);

	const [warning, setWarning] = useState(null);
	const [dashboardData, setDashboardData] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	useEffect(() => {
		loadDashboard();
	}, [selectedCurrency]);

	const loadDashboard = () => {
		setError(null);
		setAvailableCryptos(null);
		setDashboardData(null);

		setLoaderShow(true);

		SendPostRequestWithAuth(
			appLink.dashboardEndpoint,
			{
				selectedCurrency,
			},
			accessToken
		)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setDashboardData(response.data);
					setAvailableCryptos(response.data.currencies);

					if (!response.data.userData.mfaEnabled) {
						setWarning("Please enable Multi-Factor Authentication in the 'Security' tab. Whilst disabled, some account features will be disabled. Thank you");
					}
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const DisplayPayment = ({ payment, index }) => (
		<React.Fragment key={index}>
			<tr key={index}>
				<td className="goal-txref">
					{payment.from !== null && payment.from !== undefined && payment.from.length !== 0 && (
						<React.Fragment>
							From: {payment.from}
							<br />
						</React.Fragment>
					)}
					Payment ID: {payment.paymentId}
					<br />
					{payment.currencyCode.toLowerCase() === "doge" ? (
						<a href={`https://chain.so/tx/DOGE/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : payment.currencyCode.toLowerCase() === "ltc" ? (
						<a href={`https://chain.so/tx/LTC/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : payment.currencyCode.toLowerCase() === "bch" ? (
						<a href={`https://www.blockchain.com/bch/tx/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : payment.currencyCode.toLowerCase() === "eth" ? (
						<a href={`https://www.blockchain.com/eth/tx/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : (
						<a href={`https://www.blockchain.com/btc/tx/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					)}
				</td>
				<td className="goal-status">
					{payment.status.toLowerCase() === "confirmed" ? (
						<>
							<span className="text-success">●</span> Confirmed
						</>
					) : payment.status.toLowerCase() === "pending" ? (
						<>
							<span className="text-warning">●</span> Pending ( {payment.confirmation} )
						</>
					) : payment.status.toLowerCase() === "overpaid" ? (
						<>
							<span className="text-success">●</span> OverPaid
						</>
					) : payment.status.toLowerCase() === "underpaid" ? (
						<>
							<span className="text-danger">●</span> UnderPaid
						</>
					) : (
						<>
							<span className="text-danger">●</span> Failed
						</>
					)}
				</td>
				<td className="goal-amount">
					{payment.expectedCryptoAmount} {payment.currencyCode.toUpperCase()}
				</td>
				<td className="goal-date">
					<time dateTime={new Date(payment.createdDate).toString().split(" ").splice(0, 4).join(" ")}>{new Date(payment.createdDate).toString().split(" ").splice(0, 4).join(" ")}</time>
				</td>
			</tr>
		</React.Fragment>
	);
	return (
		<React.Fragment>
			<AuthContent smallText={"Overview"} bigText={"Dashboard"} buttonTitle={"Withdraw"} buttonLink={appLink.withdraw}>
				{warning !== null && (
					<div className="alert alert-warning alert-fill-warning alert-dismissible fade show" role="alert">
						{warning}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					{dashboardData !== null && (
						<React.Fragment>
							{availableCryptos !== null && (
								<div className="col-12 mb-3">
									<span>Selected: {selectedCurrency.toUpperCase()}</span>
									{availableCryptos.map((crypto, index) => (
										<React.Fragment key={index}>
											{selectedCurrency !== crypto.currencyCode && (
												<span
													key={index}
													onClick={(e) => {
														e.preventDefault();
														setSelectedCurrency(crypto.currencyCode.toLowerCase());
													}}
													style={{ cursor: "pointer", borderLeft: "2px solid #e8e8e8" }}
													className="text-primary pl-2 pr-2 float-right"
												>
													{crypto.currencyName} ({crypto.currencyCode.toUpperCase()})
												</span>
											)}
										</React.Fragment>
									))}
								</div>
							)}

							<div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">Your Available Balance</h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.balance.crypto} {selectedCurrency.toUpperCase()}
												</span>
												<br />
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													Pending payments not included.
												</small>
											</div>
											{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
										</div>{" "}
									</div>
								</div>
							</div>

							<div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">Total Withdrawals: {dashboardData.withdrawalTx.totalTx} </h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.withdrawalTx.withdrawalCryptoAmount} {selectedCurrency.toUpperCase()}
												</span>
												<br />
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													Total Fees: {dashboardData.withdrawalTx.totalWithdrawalFees}
												</small>
											</div>
											<div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.withdrawalTx.withdrawalUSDAmount).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div>
										</div>{" "}
									</div>
								</div>
							</div>

							{/* <div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">Total {selectedCurrency.toUpperCase()} RECEIVED</h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.totalReceivedData.totalReceivedCrypto} {selectedCurrency.toUpperCase()}
												</span>
											</div>
											<div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.totalReceivedData.totalReceivedUSD).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div>
											<div className="col-12">
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													Incoming/Pending Payments not included.
												</small>
											</div>
										</div>{" "}
									</div>
								</div>
							</div> */}

							<div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">Total {selectedCurrency.toUpperCase()} CONFIRMED</h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.totalReceivedData.totalReceivedCrypto} {selectedCurrency.toUpperCase()}
													{/* dashboardData.paymentsExtra.balancedPaymentCryptoAmount} {selectedCurrency.toUpperCase() */}
												</span>
											</div>
											{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.totalReceivedData.totalReceivedUSD).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> 
											<div className="col-auto">
												<span className="h2 text-mute mb-0">{dashboardData.paymentsExtra.balancedPaymentCryptoAmountCount}</span>
											</div>*/}
											<div className="col-12">
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													Only Confirmed Payments
												</small>
											</div>
										</div>{" "}
									</div>
								</div>
							</div>

							<div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">ALL PENDING PAYMENTS</h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.paymentsExtra.pendingPaymentCryptoAmount} {selectedCurrency.toUpperCase()}
												</span>
												<br />
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													All Pending Payments. Includes Zero Confirmations.
												</small>
											</div>
											{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
											<div className="col-auto">
												<span className="h2 text-mute mb-0">{dashboardData.paymentsExtra.pendingPaymentCryptoAmountCount}</span>
											</div>
										</div>{" "}
									</div>
								</div>
							</div>

							<div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">PENDING BALANCE</h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.paymentsExtra.unBalancedPaymentCryptoAmount} {selectedCurrency.toUpperCase()}
												</span>
												<br />
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													Confirmed Pending Payments Awaiting Crediting
												</small>
											</div>
											{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
											{
												<div className="col-auto">
													<span className="h2 text-mute mb-0">{dashboardData.paymentsExtra.unBalancedPaymentCryptoAmountCount}</span>
												</div>
											}
										</div>{" "}
									</div>
								</div>
							</div>

							<div className="col-12 col-lg-4 col-xl-4">
								<div className="card">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col">
												<h6 className="text-uppercase text-dark mb-2">FAILED PAYMENTS</h6>

												<span className="h2 mb-0 text-mute">
													{dashboardData.paymentsExtra.failedPaymentCryptoAmount} {selectedCurrency.toUpperCase()}
												</span>
												<br />
												<small className="text-mute" style={{ fontSize: "0.6rem" }}>
													Only Failed Payments
												</small>
											</div>
											{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
											{
												<div className="col-auto">
													<span className="h2 text-mute mb-0">{dashboardData.paymentsExtra.failedPaymentCryptoAmountCount}</span>
												</div>
											}
										</div>{" "}
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										<h4 className="card-header-title">Payments</h4>
									</div>
									<div className="col-auto">
										<Link to={appLink.payments} className="btn btn-sm btn-white">
											<span className="ml-3 mr-3">View Payments</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="table-responsive mb-0">
								{!loaderShow ? (
									<table className="table table-sm table-nowrap card-table">
										<thead>
											<tr>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-txref">
														TxRef
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-status">
														Status
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-amount">
														Amount
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-date">
														Date
													</a>
												</th>
												<th />
											</tr>
										</thead>
										<tbody className="list">
											{dashboardData !== null && dashboardData.payments.length > 0 ? (
												dashboardData.payments.map((payment, index) => <DisplayPayment payment={payment} index={index} />)
											) : (
												<React.Fragment>
													<tr>
														<td colSpan={5} className="goal-amount text-center">
															No payments have been made to your account. Share your <Link to={appLink.paymentLinks}>payment link</Link> to start receiving cryptopayments.
														</td>
													</tr>
												</React.Fragment>
											)}
										</tbody>
									</table>
								) : (
									<FormLoading />
								)}
							</div>
						</div>
					</div>
				</div>{" "}
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
