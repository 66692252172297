/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { AuthContent } from "../util/authWrapper";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";
import { isMobile } from "react-device-detect";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [loaderStatus, setLoaderStatus] = useState("accounts");
	const [warning, setWarning] = useState(null);
	const [error, setError] = useState(null);
	const [withdrawalParameters, setWithdrawalParameters] = useState({
		selectedAccount: null,
		selectedCurrency: "",
		withdrawalAddress: "",
		amountError: null,
		sendingAmount: "",
		otpCode: "",
		estimatedDelivery: "NORMAL",
	});
	const [previewWithdrawal, setPreviewWithdrawal] = useState(null);
	const [sendCompleted, setSendCompleted] = useState(null);
	const [withdrawalFees, setWithdrawalFees] = useState({
		platformFee: null,
		networkFee: null,
	});
	const [accountOptions, setAccount] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	useEffect(() => {
		loadWithdrawalOptions();
	}, []);

	const loadWithdrawalOptions = () => {
		setLoaderStatus("accounts");
		setLoaderShow(true);
		setError(null);

		SendPostRequestWithAuth(appLink.withdrawOptions, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setAccount(response.data);
					setWithdrawalFees({ ...withdrawalFees, platformFee: Number(response.data.platformFee) });
					if (!response.data.mfaEnabled) {
						setWarning("Please enable Multi-Factor Authentication in the 'Security' tab. You will be unable to withdraw your funds whilst multi-factor authentication is disabled. Thank you");
					}
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const previewWithdrawalOptions = (e) => {
		e.preventDefault();

		if (withdrawalParameters.selectedAccount === null) {
			setError("Please select the account to be debited");
			return;
		}

		if (withdrawalParameters.selectedCurrency === "") {
			setError("Please select the account to be debited");
			return;
		}

		if (withdrawalParameters.withdrawalAddress.length < 20) {
			setError("Please enter a valid wallet address");
			return;
		}

		if (isNaN(withdrawalParameters.sendingAmount)) {
			setError("Please enter a valid transfer amount");
			return;
		}

		if (parseFloat(withdrawalParameters.sendingAmount) <= 0) {
			setError("Please enter a valid transfer amount");
			return;
		}

		if (withdrawalParameters.estimatedDelivery.length === 0) {
			setError("Please select a delivery timeframe");
			return;
		}

		setError(null);
		setLoaderStatus("preview");
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.previewWithdrawal, withdrawalParameters, accessToken)
			.then((response) => {
				setLoaderShow(false);
				if (response.data.success) {
					setPreviewWithdrawal(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const processWithdrawal = (e) => {
		e.preventDefault();

		if (withdrawalParameters.selectedAccount === null) {
			setError("Please select the account to be debited");
			return;
		}

		if (withdrawalParameters.selectedCurrency === "") {
			setError("Please select the account to be debited");
			return;
		}

		if (withdrawalParameters.withdrawalAddress.length < 20) {
			setError("Please enter a valid wallet address");
			return;
		}

		if (isNaN(withdrawalParameters.sendingAmount)) {
			setError("Please enter a valid transfer amount");
			return;
		}

		if (parseFloat(withdrawalParameters.sendingAmount) <= 0) {
			setError("Please enter a valid transfer amount");
			return;
		}

		if (withdrawalParameters.estimatedDelivery.length === 0) {
			setError("Please select a delivery timeframe");
			return;
		}

		if (withdrawalParameters.otpCode.length !== 6) {
			setError("Invalid authentication code. Please try again");
			return;
		}

		setError(null);
		setLoaderStatus("send");
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.sendFunds, withdrawalParameters, accessToken)
			.then((response) => {
				setLoaderShow(false);
				if (response.data.success) {
					setSendCompleted(response.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} show={false}>
				<div className="row justify-content-center mt-2">
					<div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center mt-5">
						{/* Title */}
						<h1 className="mb-3 mt-5">Withdraw Funds</h1>
						{/* Subtitle */}
						<p className="mb-1 text-muted">Withdraw your crypto balance to an exchange, wallet or external platform.</p>
					</div>

					<div className="col-12 col-lg-10 col-xl-8">
						{warning !== null && (
							<div className="mt-2 alert alert-warning alert-fill-warning alert-dismissible fade show" role="alert">
								{warning}
								<button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						)}
						{error !== null && (
							<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
								{error}
								<button type="button" className="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						)}

						{sendCompleted === null ? (
							<React.Fragment>
								{!loaderShow ? (
									<React.Fragment>
										{previewWithdrawal === null && (
											<form onSubmit={previewWithdrawalOptions}>
												<hr className="my-5" />
												<div className="row">
													<div className={`${withdrawalParameters.selectedAccount === null ? "col-12" : " col-12 col-md-6"}`}>
														<div className="form-group">
															<label>Account</label>

															<select
																className="form-control"
																required={true}
																onChange={(e) => {
																	e.preventDefault();
																	if (e.target.value.length !== 0) {
																		setWithdrawalParameters({
																			...withdrawalParameters,
																			selectedAccount: accountOptions.accounts.filter((account) => account.currencySymbol === e.target.value)[0],
																			selectedCurrency: e.target.value,
																			amountError: null,
																			sendingAmount: "",
																			withdrawalAddress: "",
																			estimatedDelivery: "NORMAL",
																		});
																	} else {
																		setWithdrawalParameters({
																			...withdrawalParameters,
																			selectedAccount: null,
																			selectedCurrency: e.target.value,
																			amountError: null,
																			sendingAmount: "",
																			withdrawalAddress: "",
																			estimatedDelivery: "NORMAL",
																		});
																	}
																}}
															>
																{withdrawalParameters.selectedAccount === null && <option value={""}>Select Account</option>}
																{accountOptions !== null &&
																	accountOptions.accounts.map((account, index) => (
																		<option key={index} value={account.currencySymbol}>
																			{account.currencyName} ( {account.currencyBalance} {account.currencySymbol.toUpperCase()} )
																		</option>
																	))}
															</select>
														</div>
													</div>

													<div className={`${withdrawalParameters.selectedAccount !== null ? "col-12 col-md-6" : ""}`}>
														{withdrawalParameters.selectedAccount !== null && (
															<React.Fragment>
																<div className="form-group">
																	<label>Amount to withdraw in {withdrawalParameters.selectedAccount.currencySymbol.toUpperCase()}</label>

																	<div className="input-group mb-3">
																		<div className="input-group-prepend">
																			<span className="input-group-text" id="basic-addon1">
																				{withdrawalParameters.selectedAccount.currencySymbol.toUpperCase()}
																			</span>
																		</div>
																		<input
																			type="text"
																			required={true}
																			onChange={(e) => {
																				if (Number(e.target.value) > Number(withdrawalParameters.selectedAccount.currencyBalance)) {
																					e.preventDefault();
																					setWithdrawalParameters({
																						...withdrawalParameters,
																						amountError: `You do not have enough funds to transfer ${e.target.value} ${withdrawalParameters.selectedAccount.currencySymbol.toUpperCase()}`,
																					});
																				} else {
																					setWithdrawalParameters({
																						...withdrawalParameters,
																						sendingAmount: e.target.value,
																						amountError: null,
																					});
																				}
																			}}
																			value={withdrawalParameters.sendingAmount}
																			className="form-control"
																			placeholder={withdrawalParameters.selectedAccount.currencyBalance}
																			aria-label={withdrawalParameters.selectedAccount.currencyBalance}
																			aria-describedby="basic-addon1"
																		/>
																		<button
																			type="button"
																			className="btn btn-primary"
																			style={{
																				borderTopLeftRadius: 0,
																				borderBottomLeftRadius: 0,
																			}}
																			onClick={(e) => {
																				e.preventDefault();
																				setWithdrawalParameters({
																					...withdrawalParameters,
																					sendingAmount: withdrawalParameters.selectedAccount.currencyBalance,
																				});
																			}}
																		>
																			All
																		</button>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>

													{withdrawalParameters.amountError !== null && (
														<div className="col-12 mb-3">
															<small className="text-danger">{withdrawalParameters.amountError}</small>
														</div>
													)}

													<div className="col-12">
														<div className="form-group">
															<label className="mb-1">Wallet address</label>

															<small className="form-text text-muted">Cryptocurrency address to receive funds</small>

															<input
																type="text"
																className="form-control"
																placeholder="Wallet address"
																onChange={(e) => {
																	e.preventDefault();
																	setWithdrawalParameters({
																		...withdrawalParameters,
																		withdrawalAddress: e.target.value,
																	});
																}}
																required={true}
															/>
														</div>
													</div>

													{accountOptions === null ? (
														<span>Unexpected error has occurred. Please reload this page</span>
													) : (
														<React.Fragment>
															<div className="col-12">
																<div className="form-group">
																	{withdrawalParameters.selectedCurrency.length > 0 && (
																		<>
																			<label>Estimated Delivery</label>
																			<br />
																		</>
																	)}

																	{withdrawalParameters.selectedCurrency.toLowerCase() === "btc" && (
																		<select
																			className="form-control"
																			onChange={(e) =>
																				setWithdrawalParameters({
																					...withdrawalParameters,
																					estimatedDelivery: e.target.value.toUpperCase(),
																				})
																			}
																			required={true}
																		>
																			{accountOptions.deliveryOptions.btc.map((delivery, index) => (
																				<option value={delivery.value} key={index}>
																					{delivery.name}
																				</option>
																			))}
																		</select>
																	)}

																	{withdrawalParameters.selectedCurrency.toLowerCase() === "doge" && (
																		<select
																			className="form-control"
																			onChange={(e) =>
																				setWithdrawalParameters({
																					...withdrawalParameters,
																					estimatedDelivery: e.target.value.toUpperCase(),
																				})
																			}
																			required={true}
																		>
																			{accountOptions.deliveryOptions.doge.map((delivery, index) => (
																				<option value={delivery.value} key={index}>
																					{delivery.name}
																				</option>
																			))}
																		</select>
																	)}

																	{withdrawalParameters.selectedCurrency.toLowerCase() === "ltc" && (
																		<select
																			className="form-control"
																			onChange={(e) =>
																				setWithdrawalParameters({
																					...withdrawalParameters,
																					estimatedDelivery: e.target.value.toUpperCase(),
																				})
																			}
																			required={true}
																		>
																			{accountOptions.deliveryOptions.ltc.map((delivery, index) => (
																				<option value={delivery.value} key={index}>
																					{delivery.name}
																				</option>
																			))}
																		</select>
																	)}

																	{withdrawalParameters.selectedCurrency.toLowerCase() === "bch" && (
																		<select
																			className="form-control"
																			onChange={(e) =>
																				setWithdrawalParameters({
																					...withdrawalParameters,
																					estimatedDelivery: e.target.value.toUpperCase(),
																				})
																			}
																			required={true}
																		>
																			{accountOptions.deliveryOptions.bch.map((delivery, index) => (
																				<option value={delivery.value} key={index}>
																					{delivery.name}
																				</option>
																			))}
																		</select>
																	)}
																</div>
															</div>
														</React.Fragment>
													)}
												</div>

												<div className="col-12 text-center">
													{/*withdrawalFees.platformFee !== null && (
														<React.Fragment>
															{Number(withdrawalFees.platformFee) === 0 ? (
																<p>There is no service fee charged on this transaction. You only pay the Blockchain network fees.</p>
															) : (
																<p>You'll be charged a service fee of {withdrawalFees.platformFee}% of the sending amount. This fee will be added to the total amount deductable from your account</p>
															)}
														</React.Fragment>
															)*/}

													<button type="submit" className="btn btn-primary w-50" disabled={warning !== null}>
														Next
													</button>
												</div>
											</form>
										)}

										{previewWithdrawal !== null && (
											<React.Fragment>
												<div className="card mt-5">
													<form onSubmit={processWithdrawal}>
														<div className="card-body">
															<h6 className="text-uppercase text-center text-muted my-4">SUMMARY</h6>

															<div className="row no-gutters align-items-center justify-content-center">
																<div className="col-auto">
																	<div className="h2 mb-0"></div>
																</div>
																<div className="col-auto">
																	<div className="display-4 mb-0">{previewWithdrawal.amount}</div>
																</div>
															</div>

															<div className="h4 text-uppercase text-center text-muted mb-5">{previewWithdrawal.amountUSD}</div>

															<div className="mb-3">
																<ul className="list-group list-group-flush">
																	<li className="list-group-item d-flex align-items-center justify-content-between px-0">
																		{!isMobile && <small>Address</small>}
																		<small>{previewWithdrawal.withdrawalAddress}</small>
																	</li>
																	<li className="list-group-item d-flex align-items-center justify-content-between px-0">
																		<small>Network Fee</small>
																		<small>{previewWithdrawal.network_fee}</small>
																	</li>
																	<li className="list-group-item d-flex align-items-center justify-content-between px-0">
																		<small>Transaction Priority</small>
																		<small>{previewWithdrawal.durationType}</small>
																	</li>
																	<li className="list-group-item d-flex align-items-center justify-content-between px-0">
																		<small>Platform Fee</small> <small>{previewWithdrawal.platformFee}</small>
																	</li>
																	<li className="list-group-item d-flex align-items-center justify-content-between px-0">
																		<small>Total</small> <small>{previewWithdrawal.amount_plus_fees}</small>
																	</li>
																</ul>
															</div>

															{!previewWithdrawal.balanceSufficient && (
																<>
																	<small className="text-danger">You do not have enough funds to proceed with this transfer</small>
																	<br />
																	<br />
																</>
															)}

															{previewWithdrawal.balanceSufficient && (
																<div className="col-12">
																	<div className="form-group">
																		<label className="mb-1">Enter MFA Code</label>

																		<small className="form-text text-muted">Enter the authentication code from your app to authenticate this transfer</small>

																		<input
																			type="text"
																			className="form-control"
																			placeholder="Enter OTP Code"
																			onChange={(e) => {
																				e.preventDefault();
																				setWithdrawalParameters({
																					...withdrawalParameters,
																					otpCode: e.target.value,
																				});
																			}}
																			disabled={!previewWithdrawal.balanceSufficient}
																			required={true}
																		/>
																	</div>
																</div>
															)}

															<small className="text-muted">
																Network fees are estimates and may vary. We try as much as possible to estimate them to ensure funds are transferred within the desired time frame. Do not stay on this page for more than
																30 seconds as network fees can change. The exact network fee used in sending the transaction will be used in computing your balance when you hit on the "Send Now" button below.
															</small>
															<br />
															<br />
															<button type="submit" disabled={!previewWithdrawal.balanceSufficient} className="btn btn-block btn-primary">
																Send Now
															</button>

															<br />
															<small className="text-danger" style={{ cursor: "pointer" }} onClick={(e) => setPreviewWithdrawal(null)}>
																Cancel Transfer
															</small>
														</div>
													</form>
												</div>
											</React.Fragment>
										)}
									</React.Fragment>
								) : (
									<FormLoading label={loaderStatus === "accounts" ? "Fetching accounts" : loaderStatus === "preview" ? "Preparing a hot transfer meal" : "Sending out funds"} />
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className="card mt-5 text-center">
									<div className="card-body ">
										<i className="fe fe-check-circle text-success" style={{ fontSize: 90 }} />
										<br />
										<span>
											Funds sent successfully.
											<br />
											<br />
											Transaction ID:{" "}
											{sendCompleted.selectedCurrency === "btc" ? (
												<small>
													<a href={`https://www.blockchain.com/btc/tx/${sendCompleted.transactionId}`} target="_blank" rel="noreferrer noopener">
														{sendCompleted.transactionId}
													</a>
												</small>
											) : sendCompleted.selectedCurrency === "bch" ? (
												<small>
													<a href={`https://www.blockchain.com/bch/tx/${sendCompleted.transactionId}`} target="_blank" rel="noreferrer noopener">
														{sendCompleted.transactionId}
													</a>
												</small>
											) : sendCompleted.selectedCurrency === "ltc" ? (
												<small>
													<a href={`https://chain.so/tx/LTC/${sendCompleted.transactionId}`} target="_blank" rel="noreferrer noopener">
														{sendCompleted.transactionId}
													</a>
												</small>
											) : sendCompleted.selectedCurrency === "eth" ? (
												<small>
													<a href={`https://www.blockchain.com/eth/tx/${sendCompleted.transactionId}`} target="_blank" rel="noreferrer noopener">
														{sendCompleted.transactionId}
													</a>
												</small>
											) : (
												<small>
													<a href={`https://chain.so/tx/DOGE/${sendCompleted.transactionId}`} target="_blank" rel="noreferrer noopener">
														{sendCompleted.transactionId}
													</a>
												</small>
											)}
										</span>
										<br />
										<br />
										<small
											className="text-danger"
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												setError(null);
												setWarning(null);
												setWithdrawalParameters({
													selectedAccount: null,
													selectedCurrency: "",
													withdrawalAddress: "",
													amountError: null,
													sendingAmount: "",
													otpCode: "",
													estimatedDelivery: "NORMAL",
												});
												setPreviewWithdrawal(null);
												setSendCompleted(null);
											}}
										>
											Close
										</small>
									</div>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
