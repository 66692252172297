/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import store from "./easyPeasy";
import AppLink from "./components/util/app_links";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute, UnAuthenticatedOnly } from "./utils/accessRoute";
import ScrollToTop from "./utils/scrollToTop";

import AuthSignIn from "./components/signin";
import AuthReset from "./components/reset";
import EmailLinkReset from "./components/reset/emailLinkIndex";
import AuthSignUp from "./components/signup";
import UserDashBoard from "./components/dashboard";
import UserPayments from "./components/payments";
import WithdrawUserFunds from "./components/withdraw";
import UserInvoice from "./components/invoice";
import UserInvoices from "./components/invoice/listIndex";
import Products from "./components/products/listIndex";
import ProductAdd from "./components/products";
import BrandProfile from "./components/brand";
import DeveloperKeys from "./components/developer";
import UserReport from "./components/reports";
import PaymentLinks from "./components/paymentLinks";
import SecuritySettings from "./components/security";
import CreateInventory from "./components/checkout";
import PaymentMerchant from "./components/payMerchant";
import PaymentInvoice from "./components/invoicePayment";
import UserTransfers from "./components/withdraw/listIndex";
import Cryptocurrencies from "./components/acceptedCryptocurrencies";

ReactDOM.render(
	<React.StrictMode>
		<StoreProvider store={store}>
			<Router>
				<ScrollToTop>
					<Switch>
						<UnAuthenticatedOnly exact path={AppLink.appRoot} component={AuthSignIn} />
						<UnAuthenticatedOnly exact path={AppLink.signIn} component={AuthSignIn} />
						<UnAuthenticatedOnly exact path={AppLink.signUp} component={AuthSignUp} />
						<UnAuthenticatedOnly exact path={AppLink.forgot} component={AuthReset} />
						<UnAuthenticatedOnly exact path={AppLink.resetPassword} component={EmailLinkReset} />

						<ProtectedRoute exact path={AppLink.dashboard} component={UserDashBoard} />
						<ProtectedRoute exact path={AppLink.report} component={UserReport} />
						<ProtectedRoute exact path={AppLink.payments} component={UserPayments} />
						<ProtectedRoute exact path={AppLink.withdraw} component={WithdrawUserFunds} />
						<ProtectedRoute exact path={AppLink.invoice} component={UserInvoice} />
						<ProtectedRoute exact path={AppLink.invoices} component={UserInvoices} />
						<ProtectedRoute exact path={AppLink.products} component={Products} />
						<ProtectedRoute exact path={AppLink.addProduct} component={ProductAdd} />
						<ProtectedRoute exact path={AppLink.brandProfile} component={BrandProfile} />
						<ProtectedRoute exact path={AppLink.developerTokens} component={DeveloperKeys} />
						<ProtectedRoute exact path={AppLink.paymentLinks} component={PaymentLinks} />
						<ProtectedRoute exact path={AppLink.security} component={SecuritySettings} />
						<ProtectedRoute exact path={AppLink.transfers} component={UserTransfers} />
						<ProtectedRoute exact path={AppLink.currencies} component={Cryptocurrencies} />

						<ProtectedRoute exact path={AppLink.checkOutCreate} component={CreateInventory} />

						<Route exact path={AppLink.payment} component={PaymentMerchant} />
						<Route exact path={AppLink.invoiceApp} component={PaymentInvoice} />
					</Switch>
				</ScrollToTop>
			</Router>
		</StoreProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
