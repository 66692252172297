/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { AuthContent } from "../util/authWrapper";
import React, { useEffect, useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { uuidValidateV4 } from "../util/misc";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [paymentsData, setPaymentsData] = useState(null);

	const [searchPaymentParams, setSearchPaymentParams] = useState({
		filter: "paymentId",
		searchTarget: "",
		selectedCurrency: "btc",
		dateSearchStatus: "all",
		endDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
	});
	const [uiSearchMode, setUISearchMode] = useState(false);
	const [searchData, setSearchData] = useState(null);
	const [searching, setSearching] = useState(false);
	const [currencies, setCurrencies] = useState(null);
	const [searchError, setSearchError] = useState(null);

	const { accessToken } = useStoreState((state) => state.account);

	const loadPayments = () => {
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.paymentsEndpoint, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setPaymentsData(response.data);
					setCurrencies(response.data.currencies);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		loadPayments();
	}, []);

	const DisplayPayment = ({ payment, index }) => (
		<React.Fragment key={index}>
			<tr key={index}>
				<td className="goal-txref">
					{payment.from !== null && payment.from !== undefined && payment.from.length !== 0 && (
						<React.Fragment>
							From: {payment.from}
							<br />
						</React.Fragment>
					)}
					Payment ID: {payment.paymentId}
					<br />
					{payment.currencyCode.toLowerCase() === "doge" ? (
						<a href={`https://chain.so/tx/DOGE/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : payment.currencyCode.toLowerCase() === "ltc" ? (
						<a href={`https://chain.so/tx/LTC/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : payment.currencyCode.toLowerCase() === "bch" ? (
						<a href={`https://www.blockchain.com/bch/tx/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : payment.currencyCode.toLowerCase() === "eth" ? (
						<a href={`https://www.blockchain.com/eth/tx/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					) : (
						<a href={`https://www.blockchain.com/btc/tx/${payment.transactionId}`} target="_blank" rel="noreferrer noopener">
							{payment.transactionId}
						</a>
					)}
				</td>
				<td className="goal-status">
					{payment.status.toLowerCase() === "confirmed" ? (
						<>
							<span className="text-success">●</span> Confirmed
						</>
					) : payment.status.toLowerCase() === "pending" ? (
						<>
							<span className="text-warning">●</span> Pending ( {payment.confirmation} )
						</>
					) : payment.status.toLowerCase() === "overpaid" ? (
						<>
							<span className="text-success">●</span> OverPaid
						</>
					) : payment.status.toLowerCase() === "underpaid" ? (
						<>
							<span className="text-danger">●</span> UnderPaid
						</>
					) : (
						<>
							<span className="text-danger">●</span> Failed
						</>
					)}
				</td>
				<td className="goal-amount">
					{payment.expectedCryptoAmount} {payment.currencyCode.toUpperCase()}
				</td>
				<td className="goal-date">
					<time dateTime={new Date(payment.createdDate).toString().split(" ").splice(0, 4).join(" ")}>{new Date(payment.createdDate).toString().split(" ").splice(0, 4).join(" ")}</time>
				</td>
			</tr>
		</React.Fragment>
	);

	const searchPayment = (e) => {
		e.preventDefault();

		setError(null);

		if (searchPaymentParams.filter === "paymentId" && !uuidValidateV4(searchPaymentParams.searchTarget)) {
			setSearchError("The payment id is invalid. Kindly check and try again");
			return;
		}

		if (searchPaymentParams.filter === "transactionId" && searchPaymentParams.searchTarget.length !== 64) {
			setSearchError("The transaction id is invalid. Kindly check and try again");
			return;
		}

		setSearchData(null);
		setSearchError(null);
		setSearching(true);

		SendPostRequestWithAuth(appLink.paymentSearchEndpoint, searchPaymentParams, accessToken)
			.then((response) => {
				setSearching(false);

				if (response.data.success) {
					setPaymentsData(null);
					setSearchData(response.data.payment);
				} else {
					setSearchError(response.data.message);
				}
			})
			.catch((error) => {
				setSearching(false);
				setSearchError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Overview"} bigText={"Payments"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					{uiSearchMode && (
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<div className="row align-items-center">
										<div className="col-12 col-md-8">
											<h4 className="card-header-title">Search Payments</h4>
										</div>

										<div className="col-12 col-md-4">
											{/* Title */}

											<h4 className="card-header-title text-danger text-right" style={{ cursor: "pointer" }} onClick={() => setUISearchMode(false)}>
												Close
											</h4>
										</div>
									</div>
								</div>

								<div className="card-body ">
									{!searching ? (
										<form onSubmit={searchPayment}>
											<div className="row">
												{searchError !== null && (
													<div className="col-12 mb-2">
														<span className="text-danger">{searchError}</span>
													</div>
												)}

												<div className="col-12 col-md-3">
													<div className="form-group">
														<label>Filter</label>

														<select
															className="form-control"
															required={true}
															value={searchPaymentParams.filter}
															onChange={(e) => {
																e.preventDefault();
																setSearchPaymentParams({
																	...searchPaymentParams,
																	filter: e.target.value,
																});
															}}
														>
															<option value={"paymentId"}>Search by Payment ID</option>
															<option value={"transactionId"}>Search by Transaction ID</option>
															<option value={"date-range"}>Search by Date Range</option>
														</select>
													</div>
												</div>

												<div className="col-12 col-md-7">
													{searchPaymentParams.filter === "paymentId" ? (
														<div className="form-group">
															<label>Payment ID</label>

															<input
																type="text"
																value={searchPaymentParams.searchTarget}
																className="form-control"
																placeholder="Payment ID eg. xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
																required={true}
																onChange={(e) => {
																	e.preventDefault();
																	setSearchPaymentParams({
																		...searchPaymentParams,
																		searchTarget: e.target.value,
																	});
																}}
															/>
														</div>
													) : searchPaymentParams.filter === "date-range" ? (
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-md-3">
																	<label>Start Date</label>

																	<input
																		type="date"
																		value={searchPaymentParams.startDate}
																		className="form-control"
																		placeholder="Start Date"
																		required={true}
																		onChange={(e) => {
																			e.preventDefault();
																			setSearchPaymentParams({
																				...searchPaymentParams,
																				searchTarget: "00000",
																				startDate: e.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<label>
																		End Date <small className="text-info">( * Exclusive )</small>{" "}
																	</label>

																	<input
																		type="date"
																		value={searchPaymentParams.endDate}
																		className="form-control"
																		placeholder="End Date"
																		required={true}
																		onChange={(e) => {
																			e.preventDefault();
																			setSearchPaymentParams({
																				...searchPaymentParams,
																				searchTarget: "00000",
																				endDate: e.target.value,
																			});
																		}}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<label>Currency</label>

																	<select
																		className="form-control"
																		required={true}
																		defaultValue={searchPaymentParams.selectedCurrency}
																		onChange={(e) => {
																			e.preventDefault();
																			setSearchPaymentParams({
																				...searchPaymentParams,
																				searchTarget: "00000",
																				selectedCurrency: e.target.value,
																			});
																		}}
																	>
																		{currencies !== null &&
																			currencies.map((account, index) => (
																				<option key={index} value={account.currencyCode.toLowerCase()}>
																					{account.currencyName} ( {account.currencyCode.toUpperCase()} )
																				</option>
																			))}
																	</select>
																</div>
																<div className="col-12 col-md-3">
																	<label>Status</label>

																	<select
																		className="form-control"
																		required={true}
																		defaultValue={"all"}
																		value={searchPaymentParams.dateSearchStatus}
																		onChange={(e) => {
																			e.preventDefault();
																			setSearchPaymentParams({
																				...searchPaymentParams,
																				searchTarget: "00000",
																				dateSearchStatus: e.target.value,
																			});
																		}}
																	>
																		<option value={"all"}>All Payments</option>
																		<option value={"pending"}>Pending</option>
																		<option value={"cancelled"}>Failed</option>
																		<option value={"confirmed"}>Confirmed</option>
																	</select>
																</div>
															</div>
														</div>
													) : (
														<div className="form-group">
															<label>Transaction ID</label>

															<input
																type="text"
																value={searchPaymentParams.searchTarget}
																className="form-control"
																placeholder="Transaction ID eg. xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
																required={true}
																onChange={(e) => {
																	e.preventDefault();
																	setSearchPaymentParams({
																		...searchPaymentParams,
																		searchTarget: e.target.value,
																	});
																}}
															/>
														</div>
													)}
												</div>

												<div className="col-12 col-md-2">
													<div className="form-group">
														<label style={{ color: "transparent" }}>_</label>
														<button className="form-control btn btn-primary">Search</button>
													</div>
												</div>
											</div>
										</form>
									) : (
										<FormLoading />
									)}

									{searchData !== null &&
										(searchData.length === 0 ? (
											<span className="text-danger">There is no payment matching this search</span>
										) : (
											<div className="table-responsive mb-0">
												<table className="table table-sm table-nowrap card-table">
													<thead>
														<tr>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-txref">
																	TxRef
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-status">
																	Status
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-amount">
																	Amount
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-date">
																	Date
																</a>
															</th>
															<th />
														</tr>
													</thead>
													<tbody className="list">
														{searchData.map((payment, index) => (
															<DisplayPayment payment={payment} index={index} />
														))}
													</tbody>
												</table>
											</div>
										))}
								</div>
							</div>
						</div>
					)}

					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className={`col-12 col-md-${uiSearchMode ? "12" : "8"}`}>
										<h4 className="card-header-title">Recent Payments</h4>
									</div>
									{!uiSearchMode && (
										<div className="col-12 col-md-4">
											<h4 className="card-header-title text-primary text-right" style={{ cursor: "pointer" }} onClick={() => setUISearchMode(true)}>
												Search Payments
											</h4>
										</div>
									)}
								</div>
							</div>

							<div className="table-responsive mb-0">
								{!loaderShow ? (
									<table className="table table-sm table-nowrap card-table">
										<thead>
											<tr>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-txref">
														TxRef
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-status">
														Status
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-amount">
														Amount
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-date">
														Date
													</a>
												</th>
												<th />
											</tr>
										</thead>
										<tbody className="list">
											{paymentsData !== null && paymentsData.payments.length > 0 ? (
												paymentsData.payments.map((payment, index) => <DisplayPayment payment={payment} index={index} />)
											) : (
												<React.Fragment>
													<tr>
														<td colSpan={5} className="goal-amount text-center">
															No payments have been made to your account. Share your <Link to={appLink.paymentLinks}>payment link</Link> to start receiving cryptopayments.
														</td>
													</tr>
												</React.Fragment>
											)}
										</tbody>
									</table>
								) : (
									<FormLoading />
								)}
							</div>
						</div>

						<div className="col-12">
							<small>Due to bulk data, you cannot view all payments in the dashboard. Kindly use the "Search" feature to search for a payment.</small>
						</div>
					</div>
				</div>{" "}
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
