/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { AuthContent } from "../util/authWrapper";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth, SendFormRequestRequestWithAuth } from "../util/request";
import UnknownUser from "../../unknownUser.png";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [brandInfoUpdated, setBrandInfoUpdated] = useState(false);
	const [error, setError] = useState(null);
	const [brandData, setBrandData] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);
	const [uploadingImage, setImageUploading] = useState(false);
	const [userPhoto, setUserPhoto] = useState(null);
	const FILE_UPLOAD_LIMIT_MB = 3;

	useEffect(() => {
		loadBrandInfo();
	}, []);

	const loadBrandInfo = () => {
		setError(null);
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.getBrandInfo, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setBrandData(response.data.brand);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const updateBrandInfo = (e) => {
		e.preventDefault();

		setError(null);
		setLoaderShow(true);
		setBrandInfoUpdated(false);

		SendPostRequestWithAuth(
			appLink.updateBrandInfo,
			{
				...brandData,
				imagePhoto: undefined,
			},
			accessToken
		)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					let dData = response.data.brand;
					setBrandData({
						...brandData,
						dData,
					});
					setBrandInfoUpdated(true);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		if (userPhoto !== null) {
			setImageUploading(true);

			const photoData = new FormData();
			photoData.append("upload", userPhoto);

			SendFormRequestRequestWithAuth(appLink.updateBrandPhoto, photoData, accessToken)
				.then(function (response) {
					setImageUploading(false);

					if (response.data.success) {
						setBrandData({
							...brandData,
							imagePhoto: response.data.imagePhoto,
						});
					} else {
						setError(response.data.message);
					}
				})
				.catch((error) => {
					setError("Unable to connect to Kollet. Check Internet");
				});
		}
	}, [userPhoto]);

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Overview"} bigText={"Brand Profle"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="card card-bleed shadow-light-lg">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										<h4 className="mb-0">
											Brand
											{brandInfoUpdated && (
												<small className="ml-2 text-success">
													<i className="fe fe-check mr-2" />
													Brand information updated
												</small>
											)}
										</h4>
									</div>
								</div>
							</div>
							<div className="card-body">
								{!loaderShow ? (
									<form onSubmit={updateBrandInfo}>
										<div className="form-group row mb-3">
											<div className="col-12 col-md-6 text-center">
												<label htmlFor="userPhoto" style={{ cursor: "pointer" }}>
													<img style={{ borderRadius: "50%" }} src={brandData === null || brandData.imagePhoto === "" ? UnknownUser : brandData.imagePhoto} width="150" height="150" alt="Brand Rep" />
												</label>
												<br />
												<input
													type="file"
													accept="image/*"
													id="userPhoto"
													name="userPhoto"
													onChange={(e) => {
														if (e.target.files.length === 1) {
															if (e.target.files[0].size > FILE_UPLOAD_LIMIT_MB * 1024 * 1024) {
																setError(`You can upload a photo of maximum size of ${FILE_UPLOAD_LIMIT_MB} MB`);
															} else {
																setUserPhoto(e.target.files[0]);
															}
														}
													}}
													hidden={true}
												/>

												{uploadingImage ? (
													<small className="text-primary">Uploading photo ... Please wait</small>
												) : (
													<small
														htmlFor="userPhoto"
														className="text-muted"
														style={{
															cursor: "pointer",
														}}
													>
														Click on photo to change your brand logo
													</small>
												)}

												<br />
											</div>

											<div className="col-12 col-md-6">
												<label htmlFor="userPhoto" style={{ cursor: "pointer" }}>
													Brand Color
												</label>

												<TwitterPicker
													color={brandData === null || brandData.color === "" ? "#2c7be5" : brandData.color}
													onChangeComplete={(color) => {
														console.log(color);
														if (brandData !== null) {
															setBrandData({
																...brandData,
																color: color.hex,
															});
														} else {
															setError("Please reload this page");
														}
													}}
												/>
											</div>
										</div>

										<div className="form-group">
											<label>Business Name</label>
											<input
												className="form-control"
												type="text"
												value={brandData === null ? "" : brandData.name}
												onChange={(e) => {
													if (brandData !== null) {
														setBrandData({
															...brandData,
															name: e.target.value,
														});
													} else {
														setError("Please reload this page");
													}
												}}
												required={true}
											/>
										</div>

										<div className="form-group">
											<label>Business Website</label>
											<input
												className="form-control"
												type="url"
												value={brandData === null ? "" : brandData.website}
												onChange={(e) => {
													if (brandData !== null) {
														setBrandData({
															...brandData,
															website: e.target.value,
														});
													} else {
														setError("Please reload this page");
													}
												}}
												required={true}
											/>
										</div>

										<div className="form-group">
											<label>Business Address</label>
											<input
												className="form-control"
												type="text"
												value={brandData === null ? "" : brandData.address}
												onChange={(e) => {
													if (brandData !== null) {
														setBrandData({
															...brandData,
															address: e.target.value,
														});
													} else {
														setError("Please reload this page");
													}
												}}
												required={true}
											/>
										</div>

										<div className="form-group">
											<label htmlFor="confirmPassword">Description / Service Provided by Business</label>
											<textarea
												className="form-control"
												rows="3"
												value={brandData === null ? "" : brandData.bio}
												onChange={(e) => {
													if (brandData !== null) {
														setBrandData({
															...brandData,
															bio: e.target.value,
														});
													} else {
														setError("Please reload this page");
													}
												}}
												required={true}
											></textarea>
										</div>
										<div className="row">
											<div className="col-12">
												<small className="text-muted">
													This information will be public and displayed to your customers when they are viewing invoices, receipts, checking out a cart, etc .<br />
													<br />
												</small>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-auto">
												<button className="btn btn-block btn-primary" type="submit">
													Update Brand Profile
												</button>
											</div>
										</div>
									</form>
								) : (
									<FormLoading />
								)}
							</div>
						</div>
					</div>

					{/*<div className="col-12">
						<div className="card card-bleed shadow-light-lg mb-6">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										{/* Heading 
										<h4 className="mb-0">Change Email</h4>
									</div>
								</div>
							</div>
							<div className="card-body">
								{/* Current password 
								<div className="form-group">
									<label htmlFor="currentPassword">Current email</label>
									<input className="form-control" id="currentPassword" type="password" />
								</div>
								{/* New password 
								<div className="form-group">
									<label htmlFor="newPassword">Confirm Password</label>
									<input className="form-control" id="newPassword" type="password" />
								</div>

								<div className="row">
									<div className="col-12 col-md-auto">
										{/* Button 
										<button className="btn btn-block btn-primary" type="submit">
											Update Email
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>*/}
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
