/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import AuthSideBar from "./authSideBar";
import AuthContent from "./authContent";

function AuthWrapper({ appLink, sideBarShow = true, ...props }) {
	return (
		<React.Fragment>
			{sideBarShow && <AuthSideBar appLink={appLink} />}

			{props.children}

			<div id="kolletModal" className="modal fade" tabIndex="-1">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="kolletModalTitle">
								Modal Title
							</h5>
							<button type="button" className="close" data-dismiss="modal">
								&times;
							</button>
						</div>
						<div className="modal-body" id="kolletModalBody">
							<p>This is a simple Bootstrap modal. Click the "Cancel button", "cross icon" or "dark gray area" to close or hide the modal.</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" data-dismiss="modal">
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export { AuthWrapper, AuthContent };
