/* eslint-disable import/no-anonymous-default-export */
export default {
	appRoot: "/",
	signIn: "/signin",
	signUp: "/signup",
	forgot: "/reset",
	dashboard: "/dashboard",
	payments: "/payments",
	withdraw: "/withdraw",
	invoice: "/invoice/create",
	invoices: "/invoices",
	transfers: "/transfers",
	currencies: "/currencies",
	products: "/products",
	report: "/report",
	security: "/security",
	checkOutCreate: "/inventory",
	checkOutList: "/inventories",
	paymentLinks: "/payment/links",
	addProduct: "/product/add",
	brandProfile: "/profile",
	developerTokens: "/developer/integrations",
	developerDocs: "https://docs.kollet.io",
	invoicePayment: "https://app.kollet.io/{x}/invoice",
	checkoutURL: "https://app.kollet.io/{x}/payment",
	checkout: "/checkout/:id/payment",
	paymentURL: "https://app.kollet.io/{x}/pay",
	invoiceApp: "/:id/invoice",
	payment: "/:id/pay",
	resetPassword: "/:id/reset",
	home: "https://kollet.io",
	pos: "https://pos.kollet.io",
	kolletpay: "https://pay.kollet.io",
	createAccountEndpoint: "https://account.kollet.io/user/create",
	resetAccountEndpoint: "https://account.kollet.io/password/reset",
	resetLinkAccountEndpoint: "https://account.kollet.io/password/resetLink",
	emailVerificationEndpont: "https://account.kollet.io/user/verifyOTP",
	authenticateAccountEndpoint: "https://account.kollet.io/user/authenticate",
	getWebHookEndpoint: "https://account.kollet.io/account/integrations/list",
	updateWebHookEndpoint: "https://account.kollet.io/account/webhook/update",
	updateApiTokenStatus: "https://account.kollet.io/account/integrations/update",
	generateAPITokenEndpoint: "https://account.kollet.io/account/token/generate",
	dashboardEndpoint: "https://account.kollet.io/account/dashboard",
	previewWithdrawal: "https://account.kollet.io/account/previewWithdrawal",
	sendFunds: "https://account.kollet.io/account/send",
	enableCurrency: "https://account.kollet.io/account/enableCurrency",
	getAllCurrencies: "https://account.kollet.io/account/currencies",
	reportSearch: "https://account.kollet.io/account/financialReportSearch",
	getAllAddresses: "https://account.kollet.io/account/paymentLinks",
	paymentsEndpoint: "https://account.kollet.io/account/payments",
	paymentSearchEndpoint: "https://account.kollet.io/account/paymentSearch",
	invoiceSearchEndpoint: "https://account.kollet.io/account/invoiceSearch",
	invoicesEndpoint: "https://account.kollet.io/account/invoices",
	transfersEndpoint: "https://account.kollet.io/account/transfers",
	transferSearchEndpoint: "https://account.kollet.io/account/transferSearch",
	getMFAStatus: "https://account.kollet.io/security/mfa/status",
	getBrandInfo: "https://account.kollet.io/account/brand",
	withdrawOptions: "https://account.kollet.io/account/withdrawalOptions",
	invoiceEndpoint: "https://account.kollet.io/account/invoice",
	cancelInvoiceEndpoint: "https://account.kollet.io/account/cancel_invoice",
	signOutEndpoint: "https://account.kollet.io/user/signout",
	updateBrandInfo: "https://account.kollet.io/account/brand/update",
	updateBrandPhoto: "https://account.kollet.io/account/photo/update",
	updateMFAStatus: "https://account.kollet.io/security/mfa/update",
	verifyMFATokenKey: "https://account.kollet.io/security/mfa/verify",
	updatePasswordEndpoint: "https://account.kollet.io/security/password/update",
	merchantProfile: "https://account.kollet.io/merchant/profile",
	invoiceInformation: "https://account.kollet.io/merchant/invoice",
};
