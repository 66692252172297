/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { AuthContent } from "../util/authWrapper";
import React, { useEffect, useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { uuidValidateV4 } from "../util/misc";
import { SendPostRequestWithAuth } from "../util/request";
import { CSVLink, CSVDownload } from "react-csv";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [paymentsData, setPaymentsData] = useState(null);
	const [availableCurrencies, setAvailableCurrencies] = useState(null);

	const [searchPaymentParams, setSearchPaymentParams] = useState({
		filter: "date-range",
		searchTarget: "",
		selectedCurrency: "btc",
		dateSearchStatus: "all",
		endDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
	});
	const [searchData, setSearchData] = useState(null);
	const [searching, setSearching] = useState(false);
	const [searchError, setSearchError] = useState(null);

	const { accessToken } = useStoreState((state) => state.account);

	const loadCurrencies = () => {
		setError(null);
		setSearching(true);

		SendPostRequestWithAuth(appLink.getAllCurrencies, {}, accessToken)
			.then(function (response) {
				setSearching(false);

				if (response.data.success) {
					setAvailableCurrencies(response.data.available);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setSearching(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		loadCurrencies();
	}, []);

	const searchPayment = (e) => {
		e.preventDefault();

		setError(null);

		if (searchPaymentParams.filter === "paymentId" && !uuidValidateV4(searchPaymentParams.searchTarget)) {
			setSearchError("The payment id is invalid. Kindly check and try again");
			return;
		}

		if (searchPaymentParams.filter === "transactionId" && searchPaymentParams.searchTarget.length !== 64) {
			setSearchError("The transaction id is invalid. Kindly check and try again");
			return;
		}

		setSearchData(null);
		setSearchError(null);
		setSearching(true);

		SendPostRequestWithAuth(appLink.reportSearch, searchPaymentParams, accessToken)
			.then((response) => {
				setSearching(false);

				if (response.data.success) {
					setPaymentsData(null);
					setSearchData(response.data);
				} else {
					setSearchError(response.data.message);
				}
			})
			.catch((error) => {
				setSearching(false);
				setSearchError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Overview"} bigText={"Report"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col-12 col-md-12">
										<h4 className="card-header-title">Search Reports</h4>
									</div>
								</div>
							</div>

							<div className="card-body ">
								{!searching ? (
									<form onSubmit={searchPayment}>
										<div className="row">
											{searchError !== null && (
												<div className="col-12 mb-2">
													<span className="text-danger">{searchError}</span>
												</div>
											)}

											<div className="col-12 col-md-10">
												<div className="form-group">
													<div className="row">
														<div className="col-12 col-md-3">
															<label>Start Date</label>

															<input
																type="date"
																value={searchPaymentParams.startDate}
																className="form-control"
																placeholder="Start Date"
																required={true}
																onChange={(e) => {
																	e.preventDefault();
																	setSearchPaymentParams({
																		...searchPaymentParams,
																		searchTarget: "00000",
																		startDate: e.target.value,
																	});
																}}
															/>
														</div>
														<div className="col-12 col-md-3">
															<label>
																End Date <small className="text-info">( * Exclusive )</small>{" "}
															</label>

															<input
																type="date"
																value={searchPaymentParams.endDate}
																className="form-control"
																placeholder="End Date"
																required={true}
																onChange={(e) => {
																	e.preventDefault();
																	setSearchPaymentParams({
																		...searchPaymentParams,
																		searchTarget: "00000",
																		endDate: e.target.value,
																	});
																}}
															/>
														</div>
														<div className="col-12 col-md-3">
															<label>Currency</label>

															<select
																className="form-control"
																required={true}
																defaultValue={searchPaymentParams.selectedCurrency}
																onChange={(e) => {
																	e.preventDefault();
																	setSearchPaymentParams({
																		...searchPaymentParams,
																		searchTarget: "00000",
																		selectedCurrency: e.target.value,
																	});
																}}
															>
																{availableCurrencies !== null &&
																	availableCurrencies.map((account, index) => (
																		<option key={index} value={account.currencyCode.toLowerCase()}>
																			{account.currencyName} ( {account.currencyCode.toUpperCase()} )
																		</option>
																	))}
															</select>
														</div>
														<div className="col-12 col-md-3">
															<label>Status</label>

															<select
																className="form-control"
																required={true}
																defaultValue={"all"}
																value={searchPaymentParams.dateSearchStatus}
																onChange={(e) => {
																	e.preventDefault();
																	setSearchPaymentParams({
																		...searchPaymentParams,
																		searchTarget: "00000",
																		dateSearchStatus: e.target.value,
																	});
																}}
															>
																<option value={"all"}>All</option>
																<option value={"pending"}>Pending</option>
																<option value={"cancelled"}>Failed</option>
																<option value={"confirmed"}>Confirmed</option>
															</select>
														</div>
													</div>
												</div>
											</div>

											<div className="col-12 col-md-2">
												<div className="form-group">
													<label style={{ color: "transparent" }}>_</label>
													<button className="form-control btn btn-primary">Generate Report</button>
												</div>
											</div>
										</div>
									</form>
								) : (
									<FormLoading />
								)}
							</div>
						</div>
					</div>

					{!loaderShow ? (
						<React.Fragment>
							<div className="col-12">
								{searchData !== null ? (
									<React.Fragment>
										<div className="row">
											<div className="col-12 col-lg-4 col-xl-4">
												<div className="card">
													<div className="card-body">
														<div className="row align-items-center">
															<div className="col">
																<h6 className="text-uppercase text-dark mb-2">Total Withdrawals: {searchData.withdrawalTx.totalTx} </h6>

																<span className="h2 mb-0 text-mute">
																	{searchData.withdrawalTx.withdrawalCryptoAmount} {searchPaymentParams.selectedCurrency.toUpperCase()}
																</span>
																<br />
																<small className="text-mute" style={{ fontSize: "0.6rem" }}>
																	Total Fees: {searchData.withdrawalTx.totalWithdrawalFees}
																</small>
															</div>
															<div className="col-auto">
																<span className="h2 text-mute mb-0">
																	{Number(searchData.withdrawalTx.withdrawalUSDAmount).toLocaleString("en-US", {
																		style: "currency",
																		currency: "USD",
																	})}
																</span>
															</div>
														</div>{" "}
													</div>
												</div>
											</div>

											<div className="col-12 col-lg-4 col-xl-4">
												<div className="card">
													<div className="card-body">
														<div className="row align-items-center">
															<div className="col">
																<h6 className="text-uppercase text-dark mb-2">Total {searchPaymentParams.selectedCurrency.toUpperCase()} CONFIRMED</h6>

																<span className="h2 mb-0 text-mute">
																	{/* dashboardData.totalReceivedData.totalReceivedCrypto} {selectedCurrency.toUpperCase() */}
																	{searchData.paymentsExtra.balancedPaymentCryptoAmount} {searchPaymentParams.selectedCurrency.toUpperCase()}
																</span>
															</div>
															{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.totalReceivedData.totalReceivedUSD).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
															<div className="col-auto">
																<span className="h2 text-mute mb-0">{searchData.paymentsExtra.balancedPaymentCryptoAmountCount}</span>
															</div>
															<div className="col-12">
																<small className="text-mute" style={{ fontSize: "0.6rem" }}>
																	Only Confirmed Payments
																</small>
															</div>
														</div>{" "}
													</div>
												</div>
											</div>

											<div className="col-12 col-lg-4 col-xl-4">
												<div className="card">
													<div className="card-body">
														<div className="row align-items-center">
															<div className="col">
																<h6 className="text-uppercase text-dark mb-2">ALL PENDING PAYMENTS</h6>

																<span className="h2 mb-0 text-mute">
																	{searchData.paymentsExtra.pendingPaymentCryptoAmount} {searchPaymentParams.selectedCurrency.toUpperCase()}
																</span>
																<br />
																<small className="text-mute" style={{ fontSize: "0.6rem" }}>
																	All Pending Payments. Includes Zero Confirmations.
																</small>
															</div>
															{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
															<div className="col-auto">
																<span className="h2 text-mute mb-0">{searchData.paymentsExtra.pendingPaymentCryptoAmountCount}</span>
															</div>
														</div>{" "}
													</div>
												</div>
											</div>

											<div className="col-12 col-lg-4 col-xl-4">
												<div className="card">
													<div className="card-body">
														<div className="row align-items-center">
															<div className="col">
																<h6 className="text-uppercase text-dark mb-2">PENDING BALANCE</h6>

																<span className="h2 mb-0 text-mute">
																	{searchData.paymentsExtra.unBalancedPaymentCryptoAmount} {searchPaymentParams.selectedCurrency.toUpperCase()}
																</span>
																<br />
																<small className="text-mute" style={{ fontSize: "0.6rem" }}>
																	Confirmed Pending Payments Awaiting Crediting
																</small>
															</div>
															{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
															{
																<div className="col-auto">
																	<span className="h2 text-mute mb-0">{searchData.paymentsExtra.unBalancedPaymentCryptoAmountCount}</span>
																</div>
															}
														</div>{" "}
													</div>
												</div>
											</div>

											<div className="col-12 col-lg-4 col-xl-4">
												<div className="card">
													<div className="card-body">
														<div className="row align-items-center">
															<div className="col">
																<h6 className="text-uppercase text-dark mb-2">FAILED PAYMENTS</h6>

																<span className="h2 mb-0 text-mute">
																	{searchData.paymentsExtra.failedPaymentCryptoAmount} {searchPaymentParams.selectedCurrency.toUpperCase()}
																</span>
																<br />
																<small className="text-mute" style={{ fontSize: "0.6rem" }}>
																	Only Failed Payments
																</small>
															</div>
															{/* <div className="col-auto">
												<span className="h2 text-mute mb-0">
													{Number(dashboardData.balance.usd).toLocaleString("en-US", {
														style: "currency",
														currency: "USD",
													})}
												</span>
											</div> */}
															{
																<div className="col-auto">
																	<span className="h2 text-mute mb-0">{searchData.paymentsExtra.failedPaymentCryptoAmountCount}</span>
																</div>
															}
														</div>{" "}
													</div>
												</div>
											</div>
										</div>

										<div className="col-12 text-center mt-3">
											<CSVLink
												className={"btn btn-outline-dark"}
												filename={`${searchPaymentParams.dateSearchStatus.toUpperCase()} Payments from ${searchPaymentParams.startDate} to ${searchPaymentParams.endDate}.csv`}
												data={searchData.export.payment.data}
												target="_blank"
											>
												Export Payments Data to CSV
											</CSVLink>

											<CSVLink
												className={"ml-5 btn btn-outline-dark"}
												filename={`${searchPaymentParams.dateSearchStatus.toUpperCase()} Withdrawals from ${searchPaymentParams.startDate} to ${searchPaymentParams.endDate}.csv`}
												data={searchData.export.withdrawal.data}
												target="_blank"
											>
												Export Withdrawals Data to CSV
											</CSVLink>
										</div>
									</React.Fragment>
								) : (
									<div className="col-12 text-center">
										<small className="text-center">Generate a report of your transactions. Date range per search is limited to 31 days.</small>
									</div>
								)}
							</div>
						</React.Fragment>
					) : (
						<FormLoading />
					)}
				</div>{" "}
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
