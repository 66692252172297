/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { AuthContent } from "../util/authWrapper";
import React, { useEffect, useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { uuidValidateV4 } from "../util/misc";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [withdrawsData, setWithdrawsData] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	const [searchWithdrawParams, setSearchPaymentParams] = useState({
		filter: "withdrawalId",
		searchTarget: "",
		selectedCurrency: "btc",
		dateSearchStatus: "all",
		endDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
	});
	const [uiSearchMode, setUISearchMode] = useState(false);
	const [searchData, setSearchData] = useState(null);
	const [searching, setSearching] = useState(false);
	const [currencies, setCurrencies] = useState(null);
	const [searchError, setSearchError] = useState(null);

	const loadWithdrawals = () => {
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.transfersEndpoint, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setWithdrawsData(response.data);
					setCurrencies(response.data.currencies);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		loadWithdrawals();
	}, []);

	const DisplayWithdraw = ({ withdraw, index }) => (
		<React.Fragment key={index}>
			<tr key={index}>
				<td className="goal-txref">
					{withdraw.from !== null && withdraw.from !== undefined && withdraw.from.length !== 0 && (
						<React.Fragment>
							From: {withdraw.from}
							<br />
						</React.Fragment>
					)}
					{withdraw.withdrawalId}
					<br />
					<small className="h6 text-primary">
						{withdraw.currencyCode.toLowerCase() === "btc" ? (
							<a href={`https://www.blockchain.com/btc/tx/${withdraw.transactionId}`} target="_blank" rel="noreferrer noopener">
								{withdraw.transactionId}
							</a>
						) : withdraw.currencyCode.toLowerCase() === "eth" ? (
							<a href={`https://www.blockchain.com/eth/tx/${withdraw.transactionId}`} target="_blank" rel="noreferrer noopener">
								{withdraw.transactionId}
							</a>
						) : withdraw.currencyCode.toLowerCase() === "ltc" ? (
							<a href={`https://chain.so/tx/LTC/${withdraw.transactionId}`} target="_blank" rel="noreferrer noopener">
								{withdraw.transactionId}
							</a>
						) : withdraw.currencyCode.toLowerCase() === "bch" ? (
							<a href={`https://www.blockchain.com/bch/tx/${withdraw.transactionId}`} target="_blank" rel="noreferrer noopener">
								{withdraw.transactionId}
							</a>
						) : (
							<a href={`https://chain.so/tx/DOGE/${withdraw.transactionId}`} target="_blank" rel="noreferrer noopener">
								{withdraw.transactionId}
							</a>
						)}
					</small>
				</td>
				<td className="goal-amount">
					{withdraw.amount} {withdraw.currencyCode.toUpperCase()} ( USD {withdraw.usdAmount} )
				</td>
				<td className="goal-address">
					{withdraw.currencyCode.toLowerCase() === "btc" ? (
						<a href={`https://www.blockchain.com/btc/address/${withdraw.address}`} target="_blank" rel="noreferrer noopener">
							{withdraw.address}
						</a>
					) : (
						<a href={`https://chain.so/address/DOGE/${withdraw.address}`} target="_blank" rel="noreferrer noopener">
							{withdraw.address}
						</a>
					)}
				</td>
				<td className="goal-date">
					<time dateTime={new Date(withdraw.createdDate).toString().split(" ").splice(0, 4).join(" ")}>{new Date(withdraw.createdDate).toString().split(" ").splice(0, 4).join(" ")}</time>
				</td>
			</tr>
		</React.Fragment>
	);

	const searchWithdraw = (e) => {
		e.preventDefault();

		if (searchWithdrawParams.filter === "withdrawId" && !uuidValidateV4(searchWithdrawParams.searchTarget)) {
			setSearchError("The withdrawal id is invalid. Kindly check and try again");
			return;
		}

		if (searchWithdrawParams.filter === "transactionId" && searchWithdrawParams.searchTarget.length !== 64) {
			setSearchError("The transaction id is invalid. Kindly check and try again");
			return;
		}

		if (searchWithdrawParams.filter === "address" && searchWithdrawParams.searchTarget.length < 20) {
			setSearchError("The address is invalid. Kindly check and try again");
			return;
		}

		setSearchData(null);
		setSearchError(null);
		setSearching(true);

		SendPostRequestWithAuth(appLink.transferSearchEndpoint, searchWithdrawParams, accessToken)
			.then((response) => {
				setSearching(false);

				if (response.data.success) {
					setWithdrawsData(null);
					setSearchData(response.data.transfers);
				} else {
					setSearchError(response.data.message);
				}
			})
			.catch((error) => {
				setSearching(false);
				setSearchError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Overview"} bigText={"Withdrawals"} buttonTitle={"Make Withdrawal"} buttonLink={appLink.withdraw}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					{uiSearchMode && (
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<div className="row align-items-center">
										<div className="col-12 col-md-8">
											<h4 className="card-header-title">Search Withdrawals</h4>
										</div>

										<div className="col-12 col-md-4">
											{/* Title */}

											<h4 className="card-header-title text-danger text-right" style={{ cursor: "pointer" }} onClick={() => setUISearchMode(false)}>
												Close
											</h4>
										</div>
									</div>
								</div>

								<div className="card-body ">
									{!searching ? (
										<form onSubmit={searchWithdraw}>
											<div className="row">
												{searchError !== null && (
													<div className="col-12 mb-2">
														<span className="text-danger">{searchError}</span>
													</div>
												)}

												<div className="col-12 col-md-3">
													<div className="form-group">
														<label>Filter</label>

														<select
															className="form-control"
															required={true}
															value={searchWithdrawParams.filter}
															onChange={(e) => {
																e.preventDefault();
																setSearchPaymentParams({
																	...searchWithdrawParams,
																	filter: e.target.value,
																});
															}}
														>
															<option value={"withdrawalId"}>Search by Withdrawal ID</option>
															<option value={"transactionId"}>Search by Transaction ID</option>
															<option value={"address"}>Search by Address</option>
															<option value={"date-range"}>Search by Date Range</option>
														</select>
													</div>
												</div>

												<div className="col-12 col-md-7">
													<div className="form-group">
														{searchWithdrawParams.filter === "withdrawalId" ? (
															<>
																<label>Withdrawal ID</label>

																<input
																	type="text"
																	value={searchWithdrawParams.searchTarget}
																	className="form-control"
																	placeholder="Withdraw ID eg. xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
																	required={true}
																	onChange={(e) => {
																		e.preventDefault();
																		setSearchPaymentParams({
																			...searchWithdrawParams,
																			searchTarget: e.target.value,
																		});
																	}}
																/>
															</>
														) : searchWithdrawParams.filter === "address" ? (
															<>
																<label>Address</label>

																<input
																	type="text"
																	value={searchWithdrawParams.searchTarget}
																	className="form-control"
																	placeholder="Email eg. user@host.com"
																	required={true}
																	onChange={(e) => {
																		e.preventDefault();
																		setSearchPaymentParams({
																			...searchWithdrawParams,
																			searchTarget: e.target.value,
																		});
																	}}
																/>
															</>
														) : searchWithdrawParams.filter === "date-range" ? (
															<div className="form-group">
																<div className="row">
																	<div className="col-12 col-md-4">
																		<label>Start Date</label>

																		<input
																			type="date"
																			value={searchWithdrawParams.startDate}
																			className="form-control"
																			placeholder="Start Date"
																			required={true}
																			onChange={(e) => {
																				e.preventDefault();
																				setSearchPaymentParams({
																					...searchWithdrawParams,
																					searchTarget: "00000",
																					startDate: e.target.value,
																				});
																			}}
																		/>
																	</div>
																	<div className="col-12 col-md-4">
																		<label>
																			End Date <small className="text-info">( * Exclusive )</small>{" "}
																		</label>

																		<input
																			type="date"
																			value={searchWithdrawParams.endDate}
																			className="form-control"
																			placeholder="End Date"
																			required={true}
																			onChange={(e) => {
																				e.preventDefault();
																				setSearchPaymentParams({
																					...searchWithdrawParams,
																					searchTarget: "00000",
																					endDate: e.target.value,
																				});
																			}}
																		/>
																	</div>
																	<div className="col-12 col-md-4">
																		<label>Currency</label>

																		<select
																			className="form-control"
																			required={true}
																			defaultValue={searchWithdrawParams.selectedCurrency}
																			onChange={(e) => {
																				e.preventDefault();
																				setSearchPaymentParams({
																					...searchWithdrawParams,
																					searchTarget: "00000",
																					selectedCurrency: e.target.value,
																				});
																			}}
																		>
																			{currencies !== null &&
																				currencies.map((account, index) => (
																					<option key={index} value={account.currencyCode.toLowerCase()}>
																						{account.currencyName} ( {account.currencyCode.toUpperCase()} )
																					</option>
																				))}
																		</select>
																	</div>
																</div>
															</div>
														) : (
															<>
																<label>Transaction ID</label>

																<input
																	type="text"
																	value={searchWithdrawParams.searchTarget}
																	className="form-control"
																	placeholder="Transaction ID eg. xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
																	required={true}
																	onChange={(e) => {
																		e.preventDefault();
																		setSearchPaymentParams({
																			...searchWithdrawParams,
																			searchTarget: e.target.value,
																		});
																	}}
																/>
															</>
														)}
													</div>
												</div>

												<div className="col-12 col-md-2">
													<div className="form-group">
														<label style={{ color: "transparent" }}>_</label>
														<button className="form-control btn btn-primary">Search</button>
													</div>
												</div>
											</div>
										</form>
									) : (
										<FormLoading />
									)}

									{searchData !== null &&
										(searchData.length === 0 ? (
											<span className="text-danger">There are no withdrawals matching this search</span>
										) : (
											<div className="table-responsive mb-0">
												<table className="table table-sm table-nowrap card-table">
													<thead>
														<tr>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-withdrawid">
																	Withdrawal Id
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-amount">
																	Amount
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-address">
																	Address
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-date">
																	Date
																</a>
															</th>
														</tr>
													</thead>
													<tbody className="list">
														{searchData.map((withdraw, index) => (
															<DisplayWithdraw withdraw={withdraw} index={index} />
														))}
													</tbody>
												</table>
											</div>
										))}
								</div>
							</div>
						</div>
					)}
					<div className="col-12">
						{/* Goals */}
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className={`col-12 col-md-${uiSearchMode ? "12" : "8"}`}>
										<h4 className="card-header-title">Recent Withdrawals</h4>
									</div>
									{!uiSearchMode && (
										<div className="col-12 col-md-4">
											<h4 className="card-header-title text-primary text-right" style={{ cursor: "pointer" }} onClick={() => setUISearchMode(true)}>
												Search Withdrawals
											</h4>
										</div>
									)}
								</div>
								{/* / .row */}
							</div>
							<div className="table-responsive mb-0">
								{!loaderShow ? (
									<table className="table table-sm table-nowrap card-table">
										<thead>
											<tr>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-withdrawid">
														Withdrawal Id
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-amount">
														Amount
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-address">
														Address
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-date">
														Date
													</a>
												</th>
											</tr>
										</thead>
										<tbody className="list">
											{withdrawsData !== null && withdrawsData.transfers.length > 0 ? (
												withdrawsData.transfers.map((withdraw, index) => <DisplayWithdraw withdraw={withdraw} index={index} />)
											) : (
												<React.Fragment>
													<tr>
														<td colSpan={5} className="goal-amount text-center">
															You have not made any withdrawals. <Link to={appLink.transfers}>Make a withdrawal </Link>.
														</td>
													</tr>
												</React.Fragment>
											)}
										</tbody>
									</table>
								) : (
									<FormLoading />
								)}
							</div>
						</div>
						<div className="col-12">
							<small>Due to bulk data, you cannot view all withdrawals in the dashboard. Kindly use the "Search" feature to search for a withdrawal.</small>
						</div>
					</div>
				</div>{" "}
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
