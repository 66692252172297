/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { AuthContent } from "../util/authWrapper";

const ComponentBody = ({ appLink, ...props }) => {
	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Overview"} bigText={"Products"} buttonTitle={"Add Product"} buttonLink={appLink.addProduct}>
				<div className="row">
					<div className="col-12">
						{/* Goals */}
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										{/* Title */}
										<h4 className="card-header-title">List of all products</h4>
									</div>
								</div>
								{/* / .row */}
							</div>
							<div className="table-responsive mb-0">
								<table className="table table-sm table-nowrap card-table">
									<thead>
										<tr>
											<th></th>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-invoiceid">
													ProductID
												</a>
											</th>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-status">
													Status
												</a>
											</th>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-amount">
													Amount
												</a>
											</th>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-date">
													Date
												</a>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody className="list">
										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">HSP7893</td>
											<td className="goal-status">
												<span className="text-success">●</span> Active
											</td>
											<td className="goal-amount">50 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>

										<tr>
											<td className="">
												<img src={"/assets/img/logo.svg"} width={50} height={50} alt="..." />
											</td>
											<td className="goal-txref">QSP6864</td>
											<td className="goal-status">
												<span className="text-danger">●</span> Inactive
											</td>
											<td className="goal-amount">90 USD</td>
											<td className="goal-date">
												<time dateTime="2018-10-24">07/24/18</time>
											</td>
											<td>
												<button className="btn btn-sm btn-primary mr-2">View</button>
												<button className="btn btn-sm btn-danger">Delete</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>{" "}
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
