import axios from "axios";
axios.defaults.withCredentials = true;

const validateStatus = (status) => status === 200 || status === 201;
const requestHeaders = {
	"Content-Type": "application/json",
};

const SendPostRequest = (url, data) => {
	return axios.post(url, data, {
		headers: requestHeaders,
		validateStatus: validateStatus,
	});
};

const SendPostRequestWithAuth = (url, data, token) => {
	//const account = useStoreState((state) => state.account);

	data = {
		...data,
		accessToken: token,
	};

	const request = axios.post(url, data, {
		headers: {
			...requestHeaders,
		},
		validateStatus: validateStatus,
	});

	request.then(function (response) {
		if (response.data.invalidToken) {
			localStorage.clear();
			window.location.reload();
			return null;
		}
	});

	return request;
};

const SendFormRequestRequestWithAuth = (url, photoData, token) => {
	//const account = useStoreState((state) => state.account);

	photoData.append("accessToken", token);

	const request = axios.post(url, photoData, {
		headers: {
			...requestHeaders,
		},
		validateStatus: validateStatus,
	});

	request.then(function (response) {
		if (response.data.invalidToken) {
			localStorage.clear();
			window.location.reload();
			return null;
		}
	});

	return request;
};

const SendGetRequest = (url) => {
	return axios.get(url, {
		headers: requestHeaders,
		validateStatus: validateStatus,
	});
};

export { SendPostRequest, SendGetRequest, SendPostRequestWithAuth, SendFormRequestRequestWithAuth };
