/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Link } from "react-router-dom";

export default function AuthContent({ smallText, bigText, show = true, TopRightComponent = null, hideBtn = false, buttonTitle, buttonLink, ...props }) {
	return (
		<React.Fragment>
			<div className="main-content">
				{show && (
					<div className="header mt-2">
						<div className="container-fluid">
							{/* Body */}
							<div className="header-body">
								<div className="row align-items-end">
									<div className="col">
										<h6 className="header-pretitle">{smallText}</h6>
										{/* Title */}
										<h1 className="header-title">{bigText}</h1>
									</div>
									{!hideBtn && (
										<div className="col-auto">
											{TopRightComponent !== null ? (
												<TopRightComponent />
											) : (
												<Link to={buttonLink} className="btn btn-primary lift">
													<span className="ml-5 mr-5">{buttonTitle}</span>
												</Link>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="container-fluid">{props.children}</div>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
			</div>
		</React.Fragment>
	);
}
