/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { AuthContent } from "../util/authWrapper";

const ComponentBody = ({ appLink, ...props }) => {
	return (
		<React.Fragment>
			<AuthContent appLink={appLink} show={false}>
				<div className="row justify-content-center mt-2">
					<div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center mt-5">
						{/* Title */}
						<h1 className="mb-3 mt-5">Product</h1>
						{/* Subtitle */}
						<p className="mb-1 text-muted">Add a product to your Point of Sales collection</p>
					</div>

					<div className="col-12 col-lg-10 col-xl-8">
						<form>
							<hr className="my-5" />
							<div className="row">
								<div className="col-12">
									{/* Email address */}
									<div className="form-group">
										{/* Label */}
										<label className="mb-1">Product Name</label>
										{/* Input */}
										<input type="text" className="form-control" placeholder="Name of product" />
									</div>
								</div>

								<div className="col-12 col-md-12">
									{/* First name */}
									<div className="form-group">
										{/* Label */}
										<label>Product Price</label>
										{/* Input */}
										<div className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text" id="basic-addon1">
													$
												</span>
											</div>
											<input type="text" className="form-control" placeholder="Price of the product eg 50" aria-label="100" aria-describedby="basic-addon1" />
										</div>
									</div>
								</div>

								<div className="col-12">
									{/* Email address */}
									<div className="form-group">
										{/* Label */}
										<label className="mb-1">Product Photo</label>
										{/* Input */}
										<input type="file" className="form-control" placeholder="Your name / brand name" />
									</div>
								</div>

								<div className="col-12">
									{/* Email address */}
									<div className="form-group">
										{/* Label */}
										<label className="mb-1">Product Description</label>
										{/* Form text */}
										<textarea className="form-control" placeholder="A description of the product" rows="5"></textarea>
									</div>
								</div>
							</div>
							{/* / .row */}
							{/* Button */}
							<div className="col-12 text-center">
								<button className="btn btn-primary w-50">Add a Product</button>
							</div>
						</form>
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
