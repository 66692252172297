/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormLoading from "../loader/form_loading";
import { useStoreActions } from "easy-peasy";
import { SendPostRequest } from "../util/request";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import VerificationOTP from "../../verification-otp-web.svg";

const ComponentBody = ({ appLink, ...props }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [verificationToken, setVerificationToken] = useState(null);
	const [showPassword, setPasswordShow] = useState(false);
	const [showTFA, setTFAShow] = useState(true);
	const [loaderShow, setLoaderShow] = useState(false);
	const [tfaRequired, setTFARequired] = useState(false);
	const [tfaCode, setTFACode] = useState("");
	const [error, setError] = useState(null);
	const [formTitle, setFormTitle] = useState("Sign In");

	const authenticateUser = useStoreActions((actions) => actions.authenticateUser);

	const passwordValidator = (key) => key.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,25}$/);

	useEffect(() => {
		document.body.classList.add("bg-auth");

		return () => {
			document.body.classList.remove("bg-auth");
		};
	}, []);

	const processForm = (e) => {
		e.preventDefault();

		let usernameReg = !/[^a-z\d]/i.test(username);

		if (!usernameReg) {
			setError("Only alpha-numeric characters are allowed for your username");
			return;
		}

		if (username.length < 6 || username.length > 25) {
			setError("The length of your username should be between 6 and 25 characters");
			return;
		}

		if (!passwordValidator(password)) {
			setError("Password should be within 6 to 25 characters and must contain a lowercase, uppercase, number and a special character");
			return;
		}

		if (verificationToken === null) {
			setError("Check the reCaptcha box for verification");
			return;
		}

		let authData = {
			username: username,
			password: password,
			reCaptchaToken: verificationToken,
		};

		if (tfaRequired) {
			if (tfaCode.length < 6) {
				setError("Your authentication code is not valid");
				return;
			}

			authData = {
				...authData,
				tfaCode: tfaCode,
			};
		}

		setError(null);
		setLoaderShow(true);

		SendPostRequest(appLink.authenticateAccountEndpoint, authData)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					authenticateUser(response.data.account);
				} else {
					if (response.data.tfaRequired) {
						setFormTitle("Verify with OTP Code");
						setTFARequired(true);
					}

					if (response.data.tfaRequired && tfaCode.length === 0) {
						return;
					}

					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const resetTwoFA = (e) => {
		e.preventDefault();
		setFormTitle("Sign In");
		setPassword("");
		setTFACode("");
		setVerificationToken("");
		setTFARequired(false);
	};

	return (
		<React.Fragment>
			<div className="col-12 col-md-6 col-xl-6 my-2 bg-white p-5" style={{ borderRadius: 10 }}>
				<h1 className="display-4 text-center">{formTitle}</h1>

				<p className="text-muted text-center mb-5">Manage your assets and funds</p>

				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					!tfaRequired ? (
						<form className="mt-5 mb-3 pl-5 pr-5 pb-1" onSubmit={processForm}>
							<div className="form-group">
								<label>Username</label>

								<input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value.trim())} placeholder="Enter your username" required={true} />
							</div>

							<div className="form-group">
								<div className="row">
									<div className="col">
										<label>Password</label>
									</div>
									<div className="col-auto">
										<Link to={appLink.forgot} className="form-text small text-muted">
											Forgot password?
										</Link>
									</div>
								</div>

								<div className="input-group input-group-merge">
									<input
										type={showPassword ? "text" : "password"}
										value={password}
										onChange={(e) => setPassword(e.target.value.trim())}
										className="form-control form-control-appended"
										placeholder="Enter your password"
										required={true}
									/>

									<div className="input-group-append">
										<span className="input-group-text" onClick={(e) => setPasswordShow(!showPassword)} style={{ cursor: "pointer" }}>
											<i className={`fe ${showPassword ? "fe-eye-off" : "fe-eye"}`} />
										</span>
									</div>
								</div>
							</div>

							<div className="form-group">
								<HCaptcha sitekey={"4edc6a3c-7da7-4840-ab85-ae98b6aff4d4"} onVerify={(token) => setVerificationToken(token)} />
							</div>

							<button className="btn btn-lg btn-block btn-primary mb-3">Sign in</button>

							<div className="text-center">
								<small className="text-muted text-center">
									Don't have an account yet? <Link to={appLink.signUp}>Sign up</Link>.
								</small>
							</div>
						</form>
					) : (
						<form className="mt-5 mb-1 pl-5 pr-5" onSubmit={processForm}>
							<div className="form-group text-center">
								<img src={VerificationOTP} width="300px" height="300px" alt="otp" />
								<br />
								<small>Please enter the code generated by your registered multi-factor authentication app.</small>
							</div>

							{/* Password */}
							<div className="form-group">
								<div className="row">
									<div className="col">
										<label>Enter the multi-factor code from your app </label>
									</div>
								</div>
								{/* / .row */}
								{/* Input group */}
								<div className="input-group input-group-merge">
									{/* Input */}
									<input
										type={showTFA ? "text" : "password"}
										value={tfaCode}
										minLength={6}
										maxlength={6}
										onChange={(e) => setTFACode(e.target.value.trim())}
										className="form-control form-control-appended"
										placeholder="Enter your otp code"
										required={true}
									/>
									{/* Icon */}
									<div className="input-group-append">
										<span className="input-group-text" onClick={(e) => setTFAShow(!showTFA)} style={{ cursor: "pointer" }}>
											<i className={`fe ${showTFA ? "fe-eye-off" : "fe-eye"}`} />
										</span>
									</div>
								</div>
							</div>

							<button className="btn btn-lg btn-block btn-primary mb-3">Verify and Login</button>

							<span onClick={resetTwoFA} className="text-danger" style={{ cursor: "pointer" }}>
								Cancel Login
							</span>
						</form>
					)
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default ComponentBody;
