/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { validate as uuidValidate } from "uuid";
import { Link } from "react-router-dom";
import FormLoading from "../loader/form_loading";
import { SendPostRequest } from "../util/request";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const ComponentBody = ({ appLink, ...props }) => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [username, setUsername] = useState("");
	const [emailCode, setEmailCode] = useState("");
	const [showPassword, setPasswordShow] = useState(false);
	const [resetId, setResetId] = useState("");
	const [verificationToken, setVerificationToken] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const [verificationRequestSent, setVerificationRequestSent] = useState(false);
	const [error, setError] = useState(null);
	const [formTitle, setFormTitle] = useState("Password Reset");

	useEffect(() => {
		document.body.classList.add("bg-auth");

		try {
			let m = window.location.pathname.split("/")[1];

			if (!uuidValidate(m)) {
				window.location.assign(appLink.appRoot);
				return null;
			}

			setEmailCode(document.location.search.split("=")[1]);

			setResetId(m);
		} catch (error) {
			window.location.assign(appLink.appRoot);
		}

		return () => {
			document.body.classList.remove("bg-auth");
		};
	}, []);

	const processForm = (e) => {
		e.preventDefault();

		let usernameReg = !/[^a-z\d]/i.test(username);

		if (!usernameReg) {
			setError("Only alpha-numeric characters are allowed for your username");
			return;
		}

		if (username.length < 6 || username.length > 25) {
			setError("The length of your username should be between 6 and 25 characters");
			return;
		}

		if (verificationToken === null) {
			setError("Check the reCaptcha box for verification");
			return;
		}

		let authData = {
			username,
			resetId,
			emailCode,
			confirmPassword,
			password,
			reCaptchaToken: verificationToken,
		};

		setError(null);
		setLoaderShow(true);

		SendPostRequest(appLink.resetLinkAccountEndpoint, authData)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setFormTitle("Password Changed");

					setVerificationRequestSent(true);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="col-12 col-md-6 col-xl-6 my-4 bg-white p-5" style={{ borderRadius: 10 }}>
				{/* Heading */}
				<h1 className="display-4 text-center">{formTitle}</h1>
				{/* Subheading */}
				{!verificationRequestSent && <p className="text-muted text-center mb-5">Confirm the following details to reset your account</p>}

				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					!verificationRequestSent ? (
						<form className="mt-4 mb-1 pl-5 pr-5 pb-0" onSubmit={processForm}>
							<div className="form-group">
								<label>Username</label>

								<input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value.trim())} placeholder="Enter your username" required={true} />
							</div>

							<div className="form-group">
								<label>New Password</label>

								<div className="input-group input-group-merge">
									<input
										type={showPassword ? "text" : "password"}
										value={password}
										onChange={(e) => setPassword(e.target.value.trim())}
										className="form-control form-control-appended"
										placeholder="Enter your new password"
										required={true}
									/>

									<div className="input-group-append">
										<span className="input-group-text" onClick={(e) => setPasswordShow(!showPassword)} style={{ cursor: "pointer" }}>
											<i className={`fe ${showPassword ? "fe-eye-off" : "fe-eye"}`} />
										</span>
									</div>
								</div>
							</div>

							<div className="form-group">
								<label>Confirm Password</label>

								<div className="input-group input-group-merge">
									<input
										type={showPassword ? "text" : "password"}
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value.trim())}
										className="form-control form-control-appended"
										placeholder="Confirm your new password"
										required={true}
									/>

									<div className="input-group-append">
										<span className="input-group-text" onClick={(e) => setPasswordShow(!showPassword)} style={{ cursor: "pointer" }}>
											<i className={`fe ${showPassword ? "fe-eye-off" : "fe-eye"}`} />
										</span>
									</div>
								</div>
							</div>

							<div className="form-group">
								<HCaptcha sitekey={"4edc6a3c-7da7-4840-ab85-ae98b6aff4d4"} onVerify={(token) => setVerificationToken(token)} />
							</div>

							<button className="btn btn-lg btn-block btn-primary mb-3">Change My Password</button>

							<div className="text-center">
								<small className="text-muted text-center">
									<a href={appLink.appRoot}>Go Home</a>.
								</small>
							</div>
						</form>
					) : (
						<div className="col-12">
							<div className="col-12 text-center">
								<i className="fe fe-inbox text-primary" style={{ fontSize: 90 }} />
							</div>

							<div className="col-12 text-center">
								<span>Your account password has been changed successfully. You can now sign in with your new password</span>
							</div>

							<div className="col-12 text-center">
								<Link to={appLink.signIn}>Sign In</Link>.
							</div>
						</div>
					)
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default ComponentBody;
