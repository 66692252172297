/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormLoading from "../loader/form_loading";
import { SendPostRequest } from "../util/request";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const ComponentBody = ({ appLink, ...props }) => {
	const [email, setEmail] = useState("");
	const [verificationToken, setVerificationToken] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const [verificationRequestSent, setVerificationRequestSent] = useState(false);
	const [error, setError] = useState(null);
	const [formTitle, setFormTitle] = useState("Password Reset");

	const emailValidator = (email) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

	useEffect(() => {
		document.body.classList.add("bg-auth");

		return () => {
			document.body.classList.remove("bg-auth");
		};
	}, []);

	const processForm = (e) => {
		e.preventDefault();

		if (!emailValidator(email)) {
			setError("Email is not a valid email address");
			return;
		}

		if (verificationToken === null) {
			setError("Check the reCaptcha box for verification");
			return;
		}

		let authData = {
			email: email,
			reCaptchaToken: verificationToken,
		};

		setError(null);
		setLoaderShow(true);

		SendPostRequest(appLink.resetAccountEndpoint, authData)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setFormTitle("Reset Link Sent");

					setVerificationRequestSent(true);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="col-12 col-md-6 col-xl-6 my-5 bg-white p-5" style={{ borderRadius: 10 }}>
				{/* Heading */}
				<h1 className="display-4 text-center">{formTitle}</h1>
				{/* Subheading */}
				{!verificationRequestSent && <p className="text-muted text-center mb-5">Enter your email to get a password reset link.</p>}

				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					!verificationRequestSent ? (
						<form className="mt-5 mb-5 pl-5 pr-5 pb-5" onSubmit={processForm}>
							{/* Email address */}
							<div className="form-group">
								{/* Label */}
								<label>Email</label>
								{/* Input */}
								<input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} placeholder="Enter your email" required={true} />
							</div>

							<div className="form-group">
								<HCaptcha sitekey={"4edc6a3c-7da7-4840-ab85-ae98b6aff4d4"} onVerify={(token) => setVerificationToken(token)} />
							</div>
							{/* Submit */}
							<button className="btn btn-lg btn-block btn-primary mb-3">Reset Password</button>
							{/* Link */}
							<div className="text-center">
								<small className="text-muted text-center">
									Remember your password ? <Link to={appLink.signIn}>Sign In</Link>.
								</small>
							</div>
						</form>
					) : (
						<div className="col-12">
							<div className="col-12 text-center">
								<i className="fe fe-inbox text-primary" style={{ fontSize: 90 }} />
							</div>

							<div className="col-12 text-center">
								<span>A reset email has been sent to your email. Follow the instructions to reset your account's password. You can only request password resets twice every 3 hours.</span>
							</div>

							<div className="col-12 text-center">
								<a href={appLink.home}>Go Home</a>
							</div>
						</div>
					)
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default ComponentBody;
