import React from "react";
import { Helmet } from "react-helmet";
import AuthWrapper from "../util/wrapper";
import appLink from "../util/app_links";
import ComponentBody from "./body";

const AuthReset = (props) => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Reset Account | Kollet</title>
			</Helmet>

			<AuthWrapper appLink={appLink}>
				<ComponentBody appLink={appLink} />
			</AuthWrapper>
		</React.Fragment>
	);
};

export default AuthReset;
