import React from "react";
import { Helmet } from "react-helmet";
import { AuthWrapper } from "../util/authWrapper";
import appLink from "../util/app_links";
import ComponentBody from "./body";

const SecuritySettings = (props) => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Security | Kollet</title>
			</Helmet>
			<AuthWrapper appLink={appLink}>
				<ComponentBody appLink={appLink} />
			</AuthWrapper>
		</React.Fragment>
	);
};

export default SecuritySettings;
