/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { AuthContent } from "../util/authWrapper";
import React, { useEffect, useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { uuidValidateV4 } from "../util/misc";
import { SendPostRequestWithAuth } from "../util/request";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [invoicesData, setInvoicesData] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	const [searchInvoiceParams, setSearchPaymentParams] = useState({
		filter: "invoiceId",
		searchTarget: "",
	});
	const [uiSearchMode, setUISearchMode] = useState(false);
	const [searchData, setSearchData] = useState(null);
	const [searching, setSearching] = useState(false);
	const [searchError, setSearchError] = useState(null);

	const loadInvoices = () => {
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.invoicesEndpoint, {}, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					setInvoicesData(response.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const cancelPayment = (e, invoiceId) => {
		e.preventDefault();
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.cancelInvoiceEndpoint, { invoiceId }, accessToken)
			.then((response) => {
				setLoaderShow(false);

				if (response.data.success) {
					loadInvoices();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);
				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		loadInvoices();
	}, []);

	const DisplayInvoice = ({ invoice, index }) => (
		<React.Fragment key={index}>
			<tr key={index}>
				<td className="goal-txref">
					<a className="text-dark" href={`https://app.kollet.io/${invoice.invoiceId}/invoice`} target="_blank" rel="noreferrer noopener">
						{invoice.invoiceId}
					</a>

					{invoice.duplicate && <span className="badge badge-warning">Duplicate Payment</span>}
					{invoice.status.toLowerCase() === "confirmed" || invoice.status.toLowerCase() === "pending" || invoice.status.toLowerCase() === "overpaid" || invoice.status.toLowerCase() === "underpaid" ? (
						<>
							<br />
							<small className="h6 text-primary">
								{invoice.currencyCode.toLowerCase() === "doge" ? (
									<a href={`https://chain.so/tx/DOGE/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : invoice.currencyCode.toLowerCase() === "ltc" ? (
									<a href={`https://chain.so/tx/LTC/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : invoice.currencyCode.toLowerCase() === "bch" ? (
									<a href={`https://www.blockchain.com/bch/tx/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : invoice.currencyCode.toLowerCase() === "eth" ? (
									<a href={`https://www.blockchain.com/eth/tx/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : (
									<a href={`https://www.blockchain.com/btc/tx/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								)}
							</small>
						</>
					) : invoice.status.toLowerCase() === "not_paid" ? (
						<>
							<br />

							<a href="#" onClick={(e) => cancelPayment(e, invoice.invoiceId)} className={"text-danger h6"}>
								Cancel Invoice for {atob(invoice.userFullName)}
							</a>
						</>
					) : (
						<>
							<br />

							<small className="h6 text-primary">
								{invoice.currencyCode.toLowerCase() === "doge" ? (
									<a href={`https://chain.so/tx/DOGE/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : invoice.currencyCode.toLowerCase() === "ltc" ? (
									<a href={`https://chain.so/tx/LTC/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : invoice.currencyCode.toLowerCase() === "bch" ? (
									<a href={`https://www.blockchain.com/bch/tx/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : invoice.currencyCode.toLowerCase() === "eth" ? (
									<a href={`https://www.blockchain.com/eth/tx/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								) : (
									<a href={`https://www.blockchain.com/btc/tx/${invoice.transactionId}`} target="_blank" rel="noreferrer noopener">
										{invoice.transactionId}
									</a>
								)}
							</small>
						</>
					)}
				</td>
				<td className="goal-status">
					{invoice.status.toLowerCase() === "confirmed" ? (
						<>
							<span className="text-success">●</span> Confirmed
						</>
					) : invoice.status.toLowerCase() === "pending" ? (
						<>
							<span className="text-warning">●</span> Pending ( {invoice.confirmation} )
						</>
					) : invoice.status.toLowerCase() === "overpaid" ? (
						<>
							<span className="text-success">●</span> OverPaid
						</>
					) : invoice.status.toLowerCase() === "underpaid" ? (
						<>
							<span className="text-danger">●</span> UnderPaid
						</>
					) : invoice.status.toLowerCase() === "not_paid" ? (
						<>
							<span className="text-muted">●</span> Not Paid Yet
						</>
					) : invoice.status.toLowerCase() === "cancelled" ? (
						<>
							<span className="text-danger">●</span> Cancelled
						</>
					) : (
						<>
							<span className="text-danger">●</span> Failed
						</>
					)}
				</td>

				<td className="goal-amount">
					{invoice.status.toLowerCase() === "confirmed" || invoice.status.toLowerCase() === "pending" || invoice.status.toLowerCase() === "overpaid" || invoice.status.toLowerCase() === "underpaid"
						? `USD ${Number(invoice.expectedUSDAmount).toLocaleString("en-US", {
								style: "currency",
								currency: "USD",
						  })} ( ${invoice.expectedCryptoAmount} ${invoice.currencyCode.toUpperCase()} )`
						: invoice.currencyPriority === "FIAT"
						? `USD ${Number(invoice.expectedUSDAmount).toLocaleString("en-US", {
								style: "currency",
								currency: "USD",
						  })}`
						: `${invoice.expectedCryptoAmount} ${invoice.currencyCode.toUpperCase()}`}

					{(invoice.status.toLowerCase() === "overpaid" || invoice.status.toLowerCase() === "underpaid") && (
						<>
							<br />
							<span className={invoice.status.toLowerCase() === "overpaid" ? "h6 text-primary" : "h6 text-danger"}>
								User Paid:
								{` USD ${Number(invoice.usdAmountPaid).toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
								})}`}{" "}
								( {invoice.cryptoAmountPaid} {invoice.currencyCode.toUpperCase()} )
							</span>
						</>
					)}
				</td>
				<td className="goal-name">{atob(invoice.userEmail)}</td>
				<td className="goal-date">
					<time dateTime={new Date(invoice.createdDate).toString().split(" ").splice(0, 4).join(" ")}>{new Date(invoice.createdDate).toString().split(" ").splice(0, 4).join(" ")}</time>
				</td>
				<td>
					{(invoice.status.toLowerCase() === "overpaid" || invoice.status.toLowerCase() === "confirmed" || invoice.status.toLowerCase() === "underpaid") && (
						<div class="dropdown">
							<button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<a class="dropdown-item" target="_blank" rel="noreferrer noopener" href={`https://receipts.kollet.io/docs/receipt?id=${invoice.invoiceId}&email=${atob(invoice.userEmail)}`}>
									View Receipt
								</a>
							</div>
						</div>
					)}
				</td>
			</tr>
		</React.Fragment>
	);

	const searchInvoice = (e) => {
		e.preventDefault();

		if (searchInvoiceParams.filter === "invoiceId" && !uuidValidateV4(searchInvoiceParams.searchTarget)) {
			setSearchError("The invoice id is invalid. Kindly check and try again");
			return;
		}

		if (searchInvoiceParams.filter === "transactionId" && searchInvoiceParams.searchTarget.length !== 64) {
			setSearchError("The transaction id is invalid. Kindly check and try again");
			return;
		}

		setSearchData(null);
		setSearchError(null);
		setSearching(true);

		SendPostRequestWithAuth(appLink.invoiceSearchEndpoint, searchInvoiceParams, accessToken)
			.then((response) => {
				setSearching(false);

				if (response.data.success) {
					setSearchData(response.data.invoices);
				} else {
					setSearchError(response.data.message);
				}
			})
			.catch((error) => {
				setSearching(false);
				setSearchError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Overview"} bigText={"Invoices"} buttonTitle={"Create Invoice"} buttonLink={appLink.invoice}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					{uiSearchMode && (
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<div className="row align-items-center">
										<div className="col-12 col-md-8">
											<h4 className="card-header-title">Search Invoices</h4>
										</div>

										<div className="col-12 col-md-4">
											{/* Title */}

											<h4 className="card-header-title text-danger text-right" style={{ cursor: "pointer" }} onClick={() => setUISearchMode(false)}>
												Close
											</h4>
										</div>
									</div>
								</div>

								<div className="card-body ">
									{!searching ? (
										<form onSubmit={searchInvoice}>
											<div className="row">
												{searchError !== null && (
													<div className="col-12 mb-2">
														<span className="text-danger">{searchError}</span>
													</div>
												)}

												<div className="col-12 col-md-3">
													<div className="form-group">
														<label>Filter</label>

														<select
															className="form-control"
															required={true}
															value={searchInvoiceParams.filter}
															onChange={(e) => {
																e.preventDefault();
																setSearchPaymentParams({
																	...searchInvoiceParams,
																	filter: e.target.value,
																});
															}}
														>
															<option value={"invoiceId"}>Search by Invoice ID</option>
															<option value={"transactionId"}>Search by Transaction ID</option>
															<option value={"email"}>Search by Email</option>
														</select>
													</div>
												</div>

												<div className="col-12 col-md-7">
													<div className="form-group">
														{searchInvoiceParams.filter === "invoiceId" ? (
															<>
																<label>Invoice ID</label>

																<input
																	type="text"
																	value={searchInvoiceParams.searchTarget}
																	className="form-control"
																	placeholder="Invoice ID eg. xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
																	required={true}
																	onChange={(e) => {
																		e.preventDefault();
																		setSearchPaymentParams({
																			...searchInvoiceParams,
																			searchTarget: e.target.value,
																		});
																	}}
																/>
															</>
														) : searchInvoiceParams.filter === "email" ? (
															<>
																<label>Email address</label>

																<input
																	type="email"
																	value={searchInvoiceParams.searchTarget}
																	className="form-control"
																	placeholder="Email eg user@host.com"
																	required={true}
																	onChange={(e) => {
																		e.preventDefault();
																		setSearchPaymentParams({
																			...searchInvoiceParams,
																			searchTarget: e.target.value,
																		});
																	}}
																/>
															</>
														) : (
															<>
																<label>Transaction ID</label>

																<input
																	type="text"
																	value={searchInvoiceParams.searchTarget}
																	className="form-control"
																	placeholder="Transaction ID eg. xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
																	required={true}
																	onChange={(e) => {
																		e.preventDefault();
																		setSearchPaymentParams({
																			...searchInvoiceParams,
																			searchTarget: e.target.value,
																		});
																	}}
																/>
															</>
														)}
													</div>
												</div>

												<div className="col-12 col-md-2">
													<div className="form-group">
														<label style={{ color: "transparent" }}>_</label>
														<button className="form-control btn btn-primary">Search</button>
													</div>
												</div>
											</div>
										</form>
									) : (
										<FormLoading />
									)}

									{searchData !== null &&
										(searchData.length === 0 ? (
											<span className="text-danger">There are no invoices matching this search</span>
										) : (
											<div className="table-responsive mb-0">
												<table className="table table-sm table-nowrap card-table">
													<thead>
														<tr>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-invoiceid">
																	InvoiceId
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-status">
																	Status
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-amount">
																	Amount
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-name">
																	Email
																</a>
															</th>
															<th>
																<a href="#" className="text-muted list-sort" data-sort="goal-date">
																	Date
																</a>
															</th>
															<th></th>
														</tr>
													</thead>
													<tbody className="list">
														{searchData.map((invoice, index) => (
															<DisplayInvoice invoice={invoice} index={index} />
														))}
													</tbody>
												</table>
											</div>
										))}
								</div>
							</div>
						</div>
					)}
					<div className="col-12">
						{/* Goals */}
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className={`col-12 col-md-${uiSearchMode ? "12" : "8"}`}>
										<h4 className="card-header-title">Recent Invoices</h4>
									</div>
									{!uiSearchMode && (
										<div className="col-12 col-md-4">
											<h4 className="card-header-title text-primary text-right" style={{ cursor: "pointer" }} onClick={() => setUISearchMode(true)}>
												Search Invoices
											</h4>
										</div>
									)}
								</div>
								{/* / .row */}
							</div>
							<div className="table-responsive mb-0">
								{!loaderShow ? (
									<table className="table table-sm table-nowrap card-table">
										<thead>
											<tr>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-invoiceid">
														InvoiceId
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-status">
														Status
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-amount">
														Amount
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-name">
														Email
													</a>
												</th>
												<th>
													<a href="#" className="text-muted list-sort" data-sort="goal-date">
														Date
													</a>
												</th>
											</tr>
										</thead>
										<tbody className="list">
											{invoicesData !== null && invoicesData.invoices.length > 0 ? (
												invoicesData.invoices.map((invoice, index) => <DisplayInvoice invoice={invoice} index={index} />)
											) : (
												<React.Fragment>
													<tr>
														<td colSpan={5} className="goal-amount text-center">
															You have not created any invoices. Invoices are another way of requesting payment from a specific person. <Link to={appLink.paymentLinks}>Create and send </Link> invoices for free.
														</td>
													</tr>
												</React.Fragment>
											)}
										</tbody>
									</table>
								) : (
									<FormLoading />
								)}
							</div>
						</div>
						<div className="col-12">
							<small>Due to bulk data, you cannot view all invoices in the dashboard. Kindly use the "Search" feature to search for an invoice.</small>
						</div>
					</div>
				</div>{" "}
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
