/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import FormLoading from "../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequestWithAuth } from "../util/request";
import { AuthContent } from "../util/authWrapper";

const ComponentBody = ({ appLink, ...props }) => {
	const [loaderShow, setLoaderShow] = useState(false);
	const [error, setError] = useState(null);
	const [webhookEndpointData, setWebHookEndpointData] = useState(null);
	const [webhookUpdated, setWebHookUpdated] = useState(false);
	const [apiKeyCreated, setAPIKeyCreated] = useState(false);
	const [webhookEndpoint, setWebHookEndpoint] = useState("");
	const [accountAPITokens, setAccountAPITokens] = useState([]);
	const [apiKeyGenerateParams, setGenerateAPIKeyData] = useState({
		apiName: "",
		viewPaymentRecords: true,
		withrawalAccountFunds: true,
		viewCreateInvoice: true,
		viewCreateProduct: true,
	});
	const [webhookEndpointPassword, setWebHookEndpointPassword] = useState("");
	const [tabUpate, setTabUpdate] = useState(null);
	const { accessToken } = useStoreState((state) => state.account);

	const loadIniitialDeveloperInfo = (loader = false) => {
		setError(null);

		if (loader) {
			setTabUpdate("all");
			setLoaderShow(true);
		}

		SendPostRequestWithAuth(appLink.getWebHookEndpoint, {}, accessToken)
			.then(function (response) {
				if (loader) {
					setLoaderShow(false);
				}

				if (response.data.success) {
					setWebHookEndpointData(response.data.webhook);
					setWebHookEndpoint(response.data.webhook.endpoint);
					setAccountAPITokens(response.data.apiTokens);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				if (loader) {
					setLoaderShow(false);

					setError("Unable to connect to Kollet. Check Internet");
				}
			});
	};
	useEffect(() => {
		loadIniitialDeveloperInfo(true);
	}, []);

	const updateWebHook = (e) => {
		e.preventDefault();

		setError(null);
		setTabUpdate("webhook");
		setWebHookUpdated(false);
		setLoaderShow(true);

		SendPostRequestWithAuth(
			appLink.updateWebHookEndpoint,
			{
				endpoint: webhookEndpoint,
				password: webhookEndpointPassword,
			},
			accessToken
		)
			.then(function (response) {
				setTabUpdate("");
				setLoaderShow(false);

				if (response.data.success) {
					setWebHookEndpointPassword("");
					setWebHookUpdated(true);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const createAPIEndpoint = (e) => {
		e.preventDefault();

		setError(null);
		setTabUpdate("token");
		setAPIKeyCreated(false);
		setLoaderShow(true);

		SendPostRequestWithAuth(appLink.generateAPITokenEndpoint, apiKeyGenerateParams, accessToken)
			.then(function (response) {
				setTabUpdate("");
				setLoaderShow(false);

				if (response.data.success) {
					setGenerateAPIKeyData({
						apiName: "",
						viewPaymentRecords: false,
						withrawalAccountFunds: false,
						viewCreateInvoice: false,
						viewCreateProduct: false,
					});
					setAPIKeyCreated(true);

					loadIniitialDeveloperInfo();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	const updateAPIStatus = (status, apiToken) => {
		setError(null);

		SendPostRequestWithAuth(
			appLink.updateApiTokenStatus,
			{
				tokenKey: apiToken.token,
				status: status,
			},
			accessToken
		)
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					loadIniitialDeveloperInfo();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<AuthContent appLink={appLink} smallText={"Developer"} bigText={"Integration"} hideBtn={true}>
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="card card-bleed shadow-light-lg">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										{/* Heading */}
										<h4 className="mb-0">
											Generate API Key{" "}
											{apiKeyCreated && (
												<small className="ml-2 text-success">
													<i className="fe fe-check mr-2" />
													API Token Created
												</small>
											)}
										</h4>
									</div>
								</div>
							</div>
							<div className="card-body">
								{apiKeyCreated && (
									<small className="ml-2 text-success">
										<i className="fe fe-check mr-2" />
										API Token Created
										<br />
										<br />
									</small>
								)}
								{!loaderShow ? (
									<form onSubmit={createAPIEndpoint}>
										<label className="mb-3">Configure your API Key. Tokens once created and configured cannot be edited</label>
										<small className="form-text text-muted">Name or Purpose for API</small>
										<div className="mb-3">
											<input
												type="text"
												className="form-control"
												minLength={5}
												required={true}
												onChange={(e) => {
													e.preventDefault();
													setGenerateAPIKeyData({
														...apiKeyGenerateParams,
														apiName: e.target.value,
													});
												}}
												placeholder="Name of API Key eg. Organization Backend Crypto API"
											/>
										</div>

										{/*

										<small className="form-text text-muted">Configure permissions for your API Key</small>
										<div className="col-12">
											<div className="row">
												<div className="col-12 col-lg-3 col-md-6">
													<div className="row">
														<div className="checklist">
															<div className="custom-control custom-checkbox checklist-control">
																<input
																	className="custom-control-input"
																	id="checklistOne"
																	type="checkbox"
																	onChange={(e) => {
																		setGenerateAPIKeyData({
																			...apiKeyGenerateParams,
																			viewPaymentRecords: !apiKeyGenerateParams.viewPaymentRecords,
																		});
																	}}
																	checked={apiKeyGenerateParams.viewPaymentRecords}
																/>
																<label className="custom-control-label" for="checklistOne"></label>
																<span
																	className="custom-control-caption"
																	style={{
																		textDecoration: "none",
																	}}
																>
																	View payment records
																</span>
															</div>
														</div>
													</div>
												</div>

												<div className="col-12 col-lg-3 col-md-6">
													<div className="row">
														<div className="checklist">
															<div className="custom-control custom-checkbox checklist-control">
																<input
																	className="custom-control-input"
																	id="checklistTwo"
																	type="checkbox"
																	onChange={(e) => {
																		setGenerateAPIKeyData({
																			...apiKeyGenerateParams,
																			withrawalAccountFunds: !apiKeyGenerateParams.withrawalAccountFunds,
																		});
																	}}
																	checked={apiKeyGenerateParams.withrawalAccountFunds}
																/>
																<label className="custom-control-label" for="checklistTwo"></label>
																<span
																	className="custom-control-caption"
																	style={{
																		textDecoration: "none",
																	}}
																>
																	Withdraw account funds
																</span>
															</div>
														</div>
													</div>
												</div>

												<div className="col-12 col-lg-3 col-md-6">
													<div className="row">
														<div className="checklist">
															<div className="custom-control custom-checkbox checklist-control">
																<input
																	className="custom-control-input"
																	id="checklistThree"
																	type="checkbox"
																	onChange={(e) => {
																		setGenerateAPIKeyData({
																			...apiKeyGenerateParams,
																			viewCreateInvoice: !apiKeyGenerateParams.viewCreateInvoice,
																		});
																	}}
																	checked={apiKeyGenerateParams.viewCreateInvoice}
																/>
																<label className="custom-control-label" for="checklistThree"></label>
																<span
																	className="custom-control-caption"
																	style={{
																		textDecoration: "none",
																	}}
																>
																	Create/View invoice
																</span>
															</div>
														</div>
													</div>
												</div>

												<div className="col-12 col-lg-3 col-md-6">
													<div className="row">
														<div className="checklist">
															<div className="custom-control custom-checkbox checklist-control">
																<input
																	className="custom-control-input"
																	id="checklistFour"
																	type="checkbox"
																	onChange={(e) => {
																		setGenerateAPIKeyData({
																			...apiKeyGenerateParams,
																			viewCreateProduct: !apiKeyGenerateParams.viewCreateProduct,
																		});
																	}}
																	checked={apiKeyGenerateParams.viewCreateProduct}
																/>
																<label className="custom-control-label" for="checklistFour"></label>
																<span
																	className="custom-control-caption"
																	style={{
																		textDecoration: "none",
																	}}
																>
																	Create/View product
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
*/}
										<div className="col-12 row pt-3">
											<button className="btn btn-outline-primary" type="submit" id="button-addon2">
												Generate Key
											</button>
										</div>
									</form>
								) : (
									(tabUpate === "token" || tabUpate === "all") && <FormLoading />
								)}
							</div>
						</div>
					</div>

					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col">
										<h4 className="card-header-title">{accountAPITokens.length !== 0 ? `List of all ${accountAPITokens.length} API keys` : `You've not yet created an API Key for third party integrations`}</h4>
									</div>
								</div>
							</div>
							<div className="table-responsive mb-0">
								<table className="table table-sm table-nowrap card-table">
									<thead>
										<tr>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-name">
													Name
												</a>
											</th>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-status">
													Status
												</a>
											</th>
											<th>
												<a href="#" className="text-muted list-sort" data-sort="goal-key">
													API Key
												</a>
											</th>
											<th />
										</tr>
									</thead>
									<tbody className="list">
										{accountAPITokens.length !== 0 ? (
											accountAPITokens.map((apiToken) => (
												<tr>
													<td className="goal-name">{apiToken.name}</td>
													<td className="goal-status">
														{apiToken.status ? <span className="text-success">●</span> : <span className="text-danger">●</span>} {apiToken.status ? "Enabled" : "Disabled"}
													</td>
													<td className="goal-key">{apiToken.token}</td>
													<td>
														{apiToken.status ? (
															<button onClick={(e) => updateAPIStatus(false, apiToken)} className="btn btn-sm btn-outline-danger">
																Disable
															</button>
														) : (
															<button onClick={(e) => updateAPIStatus(true, apiToken)} className="btn btn-sm btn-outline-success">
																Enable
															</button>
														)}
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={3} className="text-center">
													You have not created any api key for third party integrations.
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="col-12">
						{!loaderShow ? (
							<div className="card card-bleed shadow-light-lg">
								<div className="card-header">
									<h4 className="mb-0">
										Webhook Endpoint{" "}
										{webhookUpdated && (
											<small className="ml-2 text-success">
												<i className="fe fe-check mr-2" />
												Webhook endpoint updated
											</small>
										)}
									</h4>
								</div>
								<div className="card-body">
									<form onSubmit={updateWebHook}>
										<div className="row">
											<div className="col-12 col-md-12">
												<div className="form-group">
													<p>
														All events linked to your account will be signed (Shared Secret Key) and sent to your chosen webhook endpoint. Check the{" "}
														<a target="_blank" rel="noreferrer noopener" href={appLink.developerDocs}>
															Documentation
														</a>{" "}
														for instructions on how to setup your intergrations.
													</p>
												</div>
											</div>
											<div className="col-12 col-md-12">
												<div className="form-group">
													<label htmlFor="name">Shared Secret</label>
													<input className="form-control" value={webhookEndpointData !== null && webhookEndpointData.sharedSecret} type="text" placeholder={webhookEndpointData !== null && webhookEndpointData.sharedSecret} />
												</div>
											</div>
											<div className="col-12 col-md-12">
												<div className="form-group">
													<label htmlFor="name">
														Webhook URL {webhookEndpointData !== null && webhookEndpointData.endpoint.length === 0 && !webhookUpdated && <span className="text-danger">( You have not set a webhook endpoint )</span>}
													</label>
													<input
														className="form-control"
														value={webhookEndpoint}
														type="url"
														placeholder="Events will be sent to this endpoint url"
														onChange={(e) => {
															e.preventDefault();
															setWebHookEndpoint(e.target.value);
														}}
														required={true}
													/>
												</div>
											</div>
											<div className="col-12 col-md-12">
												<div className="form-group">
													<label htmlFor="name">Password</label>
													<input
														className="form-control"
														value={webhookEndpointPassword}
														onChange={(e) => {
															e.preventDefault();
															setWebHookEndpointPassword(e.target.value);
														}}
														type="password"
														minLength={6}
														required={true}
														placeholder="Enter your password to confirm the endpoint update"
													/>
												</div>
											</div>
											<div className="col-12 col-md-auto mt-2">
												{webhookUpdated && (
													<small className="ml-2 text-success">
														<i className="fe fe-check mr-2" />
														Webhook endpoint updated
														<br />
														<br />
													</small>
												)}

												<button className="btn btn-block btn-primary" type="submit">
													Update Webhook Endpoint
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						) : (
							(tabUpate === "webhook" || tabUpate === "all") && <FormLoading />
						)}
					</div>
				</div>
			</AuthContent>
		</React.Fragment>
	);
};

export default ComponentBody;
