import React from "react";
import { Redirect } from "react-router-dom";
import AppLink from "../components/util/app_links";
import { useStoreState, useStoreRehydrated } from "easy-peasy";

const ProtectedRoute = ({ path, component }) => {
	let Component = component;

	const isRehydrated = useStoreRehydrated();
	const loggedIn = useStoreState((state) => state.loggedIn);

	return isRehydrated ? loggedIn ? <Component /> : <Redirect to={AppLink.signIn + "?return_to=" + path} /> : <div>Loading ...</div>;
};

const UnAuthenticatedOnly = ({ path, component }) => {
	let Component = component;

	const isRehydrated = useStoreRehydrated();
	const loggedIn = useStoreState((state) => state.loggedIn);
	let xpath = "";

	if (window.location.search.search("return_to") === 1) {
		xpath = window.location.search.split("=")[1];
	}

	return isRehydrated ? !loggedIn ? <Component /> : <Redirect to={xpath === "" ? AppLink.dashboard : xpath} /> : <div>Loading ...</div>;
};

export { ProtectedRoute, UnAuthenticatedOnly };
