import { createStore, action, persist } from "easy-peasy";

const store = createStore(
	persist(
		{
			loggedIn: false,
			account: null,
			authenticateUser: action((state, payload) => {
				state.account = payload;
				state.loggedIn = true;
				state.signUpState = null;
			}),
			signUpState: null /* { email } */,
			setSignUpState: action((state, payload) => {
				state.signUpState = {
					email: payload,
				};
			}),
			resetSignUpState: action((state) => {
				state.signUpState = null;
			}),
			logout: action((state) => {
				state.loggedIn = false;
				state.account = null;
				state.signUpState = null;
			}),
		},
		{
			storage: "localStorage",
		}
	)
);

export default store;
