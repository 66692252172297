/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { validate as uuidValidate } from "uuid";
import FormLoading from "../loader/form_loading";
import { SendPostRequest } from "../util/request";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UnknownUser from "../../unknownUser.png";
import { validate_amount } from "../util/misc";
import Countdown from "react-countdown";

const ComponentBody = ({ appLink, ...props }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const [businessShow, showBusinessInfo] = useState(false);
	const [invoiceId, setInvoiceId] = useState(null);
	const [textCopied, setTextCopied] = useState(false);
	const [invoiceData, setInvoiceData] = useState(null);

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			window.location.reload();
			return <span className="text-danger">Invoice Expired</span>;
		} else {
			// Render a countdown

			return (
				<span className={`${Number(minutes) === 0 && Number(seconds) <= 59 && "text-danger"}`}>
					{minutes}:{seconds}
				</span>
			);
		}
	};

	const CountdownTimer = ({ timeoutLock }) => {
		return (
			<Countdown
				onTick={(x) => {
					if (invoiceData !== null && invoiceData.invoice.status === "NOT_PAID" && Number(x.seconds) % 25 === 0) {
						loadInvoiceInformation(invoiceId, true);
					}
				}}
				date={Date.now() + Number(timeoutLock)}
				renderer={renderer}
			/>
		);
	};

	const loadInvoiceInformation = (invoiceId, silent = false) => {
		if (!silent) {
			setLoaderShow(true);
		}

		SendPostRequest(appLink.invoiceInformation, {
			invoiceId,
		})
			.then(function (response) {
				if (!silent) {
					setLoaderShow(false);
				}

				if (response.data.success) {
					setInvoiceData(response.data);
					document.title = response.data.merchant.businessName !== null ? `Invoice for ${response.data.merchant.businessName}` : "Pay Invoice";
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				if (!silent) {
					setLoaderShow(false);
				}

				setError("Unable to connect to Kollet. Check Internet");
			});
	};

	useEffect(() => {
		try {
			let m = window.location.pathname.split("/")[1];

			if (!uuidValidate(m)) {
				window.location.assign(appLink.appRoot);
				return null;
			}

			setInvoiceId(m);
			loadInvoiceInformation(m);
		} catch (error) {
			window.location.assign(appLink.appRoot);
		}
	}, []);

	return (
		<React.Fragment>
			<div className="main-content">
				<div className="container-lg container-fluid">
					{!loaderShow ? (
						<React.Fragment>
							<div className="row justify-content-center pt-5">
								<div className="col-12 col-lg-7 col-xl-7 mt-0">
									<div className="card mt-1">
										{error !== null && (
											<div className="alert alert-danger alert-fill-danger alert-dismissible fade show m-5 mb-1" role="alert">
												{error}
												<button type="button" className="close" data-dismiss="alert" aria-label="Close">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										)}

										{invoiceData !== null &&
											(invoiceData.invoice.status === "NOT_PAID" ? (
												<React.Fragment>
													<div className="card-body text-center">
														<div>
															<img
																style={{ borderRadius: "50%", width: "100px", height: "100px" }}
																src={invoiceData !== null ? invoiceData.merchant.businessPhotoURL : UnknownUser}
																alt={invoiceData !== null && invoiceData.merchant.businessName}
															/>
														</div>

														{invoiceData !== null && (
															<React.Fragment>
																<h2 className="card-title mt-3">
																	<a className="text-primary" href={invoiceData !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																		{invoiceData !== null && invoiceData.merchant.businessName}
																	</a>{" "}
																	<br />
																</h2>
															</React.Fragment>
														)}

														{invoiceData !== null && (
															<React.Fragment>
																<p className="small text-muted mb-3">Invoice ID: {invoiceData.invoice.invoiceId}</p>
																{/* Badges */}
																<p className="card-text">
																	<span className="badge badge-soft-secondary">Date: {new Date().toDateString()}</span>
																</p>

																<div className="mt-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Invoice Amount ( USD )</small>
																		<h1>
																			{Number(invoiceData.invoice.expectedUSDAmount).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}
																		</h1>
																	</div>

																	<div className="col">
																		<small>Invoice Amount ( {invoiceData.invoice.currencyCode.toUpperCase()} )</small>
																		<h1>{`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`}</h1>
																	</div>
																</div>
																<div className="row align-items-center justify-content-between">
																	<div className="col-12">
																		<small>Please fulfil this invoice before the time expires.</small>
																		<br />
																		<span className="badge badge-soft-secondary pl-3 pr-3">
																			<CountdownTimer timeoutLock={invoiceData.invoice.timeoutLock} />
																		</span>
																		<br />
																		<small>New prices will be applied if the time expires.</small>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>
													{/* Footer */}
													<div className="card-footer card-footer-boxed">
														<div className="row col-12 justify-content-center">
															{invoiceData !== null && (
																<h3>
																	<b>
																		Pay {invoiceData.merchant.businessName} with {invoiceData.cryptocurrency[0].currencyName}
																	</b>
																</h3>
															)}
														</div>
														{invoiceData !== null &&
															invoiceData.cryptocurrency.map((addressData, index) => (
																<React.Fragment key={index}>
																	<div className="mt-3 row mb-3">
																		<div className="col text-center">
																			<QRCode value={`${invoiceData.invoice.address}`} size={128} />
																			<br />
																			<br />
																			<small>Scan the QR code with your payment app to get {invoiceData !== null && invoiceData.merchant.businessName}'s payment address</small>
																		</div>
																		<div className="col">
																			<div className="row">
																				<span>{invoiceData.invoice.address}</span>
																				<CopyToClipboard text={invoiceData.invoice.address} onCopy={(e) => setTextCopied(true)}>
																					<span
																						className="text-primary"
																						style={{ cursor: "pointer" }}
																						onClick={(e) => {
																							e.preventDefault();
																						}}
																					>
																						Copy payment address
																					</span>
																				</CopyToClipboard>{" "}
																				{textCopied && (
																					<span className="text-success ">
																						<i className="fe fe-check ml-2" />
																						copied
																					</span>
																				)}
																				<span className="mt-3">Send exactly {`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`} before the timer expires.</span>
																			</div>
																		</div>
																	</div>
																</React.Fragment>
															))}
													</div>
												</React.Fragment>
											) : invoiceData.invoice.status === "PENDING" ? (
												<React.Fragment>
													<div className="card-body text-center">
														<div>
															<img
																style={{ borderRadius: "50%", width: "100px", height: "100px" }}
																src={invoiceData !== null ? invoiceData.merchant.businessPhotoURL : UnknownUser}
																alt={invoiceData !== null && invoiceData.merchant.businessName}
															/>
														</div>

														{invoiceData !== null && (
															<React.Fragment>
																<h2 className="card-title mt-3">
																	<a className="text-primary" href={invoiceData !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																		{invoiceData !== null && invoiceData.merchant.businessName}
																	</a>{" "}
																	<br />
																</h2>
															</React.Fragment>
														)}

														{invoiceData !== null && (
															<React.Fragment>
																<p className="small text-muted mb-3">Invoice ID: {invoiceData.invoice.invoiceId}</p>
																{/* Badges */}
																<p className="card-text">
																	<span className="badge badge-soft-secondary">Date: {new Date().toDateString()}</span>
																</p>

																<div className="mt-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Invoice Amount ( USD )</small>
																		<h1>
																			{Number(invoiceData.invoice.expectedUSDAmount).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}
																		</h1>
																	</div>

																	<div className="col">
																		<small>Invoice Amount ( {invoiceData.invoice.currencyCode.toUpperCase()} )</small>
																		<h1>{`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`}</h1>
																	</div>
																</div>
																<div className="row align-items-center justify-content-between">
																	<div className="col-12">
																		<span className="badge badge-soft-primary pl-3 pr-3">
																			Invoice Paid and Awaiting Confirmation ( {invoiceData.invoice.confirmation} / {invoiceData.cryptocurrency[0].confirmation} )
																		</span>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>
												</React.Fragment>
											) : invoiceData.invoice.status === "CANCELLED" ? (
												<React.Fragment>
													<div className="card-body text-center">
														<div>
															<img
																style={{ borderRadius: "50%", width: "100px", height: "100px" }}
																src={invoiceData !== null ? invoiceData.merchant.businessPhotoURL : UnknownUser}
																alt={invoiceData !== null && invoiceData.merchant.businessName}
															/>
														</div>

														{invoiceData !== null && (
															<React.Fragment>
																<h2 className="card-title mt-3">
																	<a className="text-primary" href={invoiceData !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																		{invoiceData !== null && invoiceData.merchant.businessName}
																	</a>{" "}
																	<br />
																	<small className="text-primary" style={{ cursor: "pointer" }} onClick={(e) => showBusinessInfo(!businessShow)}>
																		<i className={businessShow ? "fe fe-eye-off mr-1" : "fe fe-eye mr-1"} />
																		{!businessShow ? "Show Merchant Information" : "Hide Merchant Information"}
																	</small>
																</h2>
															</React.Fragment>
														)}

														{businessShow && (
															<React.Fragment>
																<p className="card-text">
																	<small className="">{invoiceData !== null && invoiceData.merchant.businessDescription}</small>
																</p>
																<div className="mb-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Email: </small>
																		<a href={`mailto:${invoiceData !== null && invoiceData.merchant.email}`}>{invoiceData !== null && invoiceData.merchant.email}</a>
																	</div>

																	<div className="col">
																		<small>Website: </small>
																		<a href={invoiceData.merchant.businessWebsite !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																			{invoiceData.merchant.businessWebsite}
																		</a>
																	</div>
																</div>
															</React.Fragment>
														)}

														{invoiceData !== null && (
															<React.Fragment>
																<p className="small text-muted mb-3">Invoice ID: {invoiceData.invoice.invoiceId}</p>
																{/* Badges */}
																<p className="card-text">
																	<span className="badge badge-soft-secondary">Date: {new Date().toDateString()}</span>
																</p>

																<div className="mt-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Invoice Amount ( USD )</small>
																		<h1>
																			{Number(invoiceData.invoice.expectedUSDAmount).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}
																		</h1>
																	</div>

																	<div className="col">
																		<small>Invoice Amount ( {invoiceData.invoice.currencyCode.toUpperCase()} )</small>
																		<h1>{`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`}</h1>
																	</div>
																</div>
																<div className="row align-items-center justify-content-between">
																	<div className="col-12">
																		<span className="badge badge-soft-danger pl-3 pr-3">This invoice has been cancelled by {invoiceData.merchant.businessName}</span>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>
												</React.Fragment>
											) : invoiceData.invoice.status === "FAILED" ? (
												<React.Fragment>
													<div className="card-body text-center">
														<div>
															<img
																style={{ borderRadius: "50%", width: "100px", height: "100px" }}
																src={invoiceData !== null ? invoiceData.merchant.businessPhotoURL : UnknownUser}
																alt={invoiceData !== null && invoiceData.merchant.businessName}
															/>
														</div>

														{invoiceData !== null && (
															<React.Fragment>
																<h2 className="card-title mt-3">
																	<a className="text-primary" href={invoiceData !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																		{invoiceData !== null && invoiceData.merchant.businessName}
																	</a>{" "}
																	<br />
																</h2>
															</React.Fragment>
														)}

														{invoiceData !== null && (
															<React.Fragment>
																<p className="small text-muted mb-3">Invoice ID: {invoiceData.invoice.invoiceId}</p>
																{/* Badges */}

																<div className="mt-3 row align-items-center justify-content-between">
																	<div className="col-12">
																		<span className="badge badge-soft-danger pl-3 pr-3">There is an error with this invoice. Ask {invoiceData.merchant.businessName} to send you a new invoice.</span>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>
												</React.Fragment>
											) : invoiceData.invoice.status === "CONFIRMED" || invoiceData.invoice.status === "OVERPAID" ? (
												<React.Fragment>
													<div className="card-body text-center">
														<div>
															<img
																style={{ borderRadius: "50%", width: "100px", height: "100px" }}
																src={invoiceData !== null ? invoiceData.merchant.businessPhotoURL : UnknownUser}
																alt={invoiceData !== null && invoiceData.merchant.businessName}
															/>
														</div>

														{invoiceData !== null && (
															<React.Fragment>
																<h2 className="card-title mt-3">
																	<a className="text-primary" href={invoiceData !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																		{invoiceData !== null && invoiceData.merchant.businessName}
																	</a>{" "}
																	<br />
																	<small className="text-primary" style={{ cursor: "pointer" }} onClick={(e) => showBusinessInfo(!businessShow)}>
																		<i className={businessShow ? "fe fe-eye-off mr-1" : "fe fe-eye mr-1"} />
																		{!businessShow ? "Show Merchant Information" : "Hide Merchant Information"}
																	</small>
																</h2>
															</React.Fragment>
														)}

														{businessShow && (
															<React.Fragment>
																<p className="card-text">
																	<small className="">{invoiceData !== null && invoiceData.merchant.businessDescription}</small>
																</p>
																<div className="mb-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Email: </small>
																		<a href={`mailto:${invoiceData !== null && invoiceData.merchant.email}`}>{invoiceData !== null && invoiceData.merchant.email}</a>
																	</div>

																	<div className="col">
																		<small>Website: </small>
																		<a href={invoiceData.merchant.businessWebsite !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																			{invoiceData.merchant.businessWebsite}
																		</a>
																	</div>
																</div>
															</React.Fragment>
														)}

														{invoiceData !== null && (
															<React.Fragment>
																<p className="small text-muted mb-3">Invoice ID: {invoiceData.invoice.invoiceId}</p>
																{/* Badges */}
																<p className="card-text">
																	<span className="badge badge-soft-secondary">Date: {new Date().toDateString()}</span>
																</p>

																<div className="mt-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Invoice Amount ( USD )</small>
																		<h1>
																			{Number(invoiceData.invoice.expectedUSDAmount).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}
																		</h1>
																	</div>

																	<div className="col">
																		<small>Invoice Amount ( {invoiceData.invoice.currencyCode.toUpperCase()} )</small>
																		<h1>{`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`}</h1>
																	</div>
																</div>
																<div className="row align-items-center justify-content-between">
																	<div className="col-12">
																		<span className="badge badge-success pl-3 pr-3 pt-2 pb-2">Payment of this invoice has been confirmed by Kollet. {invoiceData.merchant.businessName} has been notified.</span>
																	</div>
																	<div className="col-12 mt-2">
																		<span className="">Thank you for using Kollet</span>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>
												</React.Fragment>
											) : invoiceData.invoice.status === "UNDERPAID" ? (
												<React.Fragment>
													<div className="card-body text-center">
														<div>
															<img
																style={{ borderRadius: "50%", width: "100px", height: "100px" }}
																src={invoiceData !== null ? invoiceData.merchant.businessPhotoURL : UnknownUser}
																alt={invoiceData !== null && invoiceData.merchant.businessName}
															/>
														</div>

														{invoiceData !== null && (
															<React.Fragment>
																<h2 className="card-title mt-3">
																	<a className="text-primary" href={invoiceData !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																		{invoiceData !== null && invoiceData.merchant.businessName}
																	</a>{" "}
																	<br />
																	<small className="text-primary" style={{ cursor: "pointer" }} onClick={(e) => showBusinessInfo(!businessShow)}>
																		<i className={businessShow ? "fe fe-eye-off mr-1" : "fe fe-eye mr-1"} />
																		{!businessShow ? "Show Merchant Information" : "Hide Merchant Information"}
																	</small>
																</h2>
															</React.Fragment>
														)}

														{businessShow && (
															<React.Fragment>
																<p className="card-text">
																	<small className="">{invoiceData !== null && invoiceData.merchant.businessDescription}</small>
																</p>
																<div className="mb-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Email: </small>
																		<a href={`mailto:${invoiceData !== null && invoiceData.merchant.email}`}>{invoiceData !== null && invoiceData.merchant.email}</a>
																	</div>

																	<div className="col">
																		<small>Website: </small>
																		<a href={invoiceData.merchant.businessWebsite !== null ? invoiceData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
																			{invoiceData.merchant.businessWebsite}
																		</a>
																	</div>
																</div>
															</React.Fragment>
														)}

														{invoiceData !== null && (
															<React.Fragment>
																<p className="small text-muted mb-3">Invoice ID: {invoiceData.invoice.invoiceId}</p>
																{/* Badges */}
																<p className="card-text">
																	<span className="badge badge-soft-secondary">Date: {new Date().toDateString()}</span>
																</p>

																<div className="mt-3 row align-items-center justify-content-between">
																	<div className="col">
																		<small>Invoice Amount ( USD )</small>
																		<h1>
																			{Number(invoiceData.invoice.expectedUSDAmount).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}
																		</h1>
																	</div>

																	<div className="col">
																		<small>Invoice Amount ( {invoiceData.invoice.currencyCode.toUpperCase()} )</small>
																		<h1>{`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`}</h1>
																	</div>
																</div>
																<div className="row align-items-center justify-content-between">
																	<div className="col-12">
																		<span className="badge badge-soft-danger">Invoice underpayment notice</span>
																		<br />
																		<br />
																		<small className="text-danger">
																			You have underpaid the amount specified in this invoice. Ask {invoiceData.merchant.businessName} to send you a new invoice to pay the difference of{" "}
																			{`${Number(
																				validate_amount(
																					parseFloat(invoiceData.invoice.cryptoUSDRate) * (parseFloat(invoiceData.invoice.expectedCryptoAmount) - parseFloat(invoiceData.invoice.cryptoAmountPaid)),
																					2
																				)
																			).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})} `}
																			( {`${validate_amount(invoiceData.invoice.expectedCryptoAmount - invoiceData.invoice.cryptoAmountPaid)} ${invoiceData.invoice.currencyCode.toUpperCase()}`}).
																			{Number(invoiceData.cryptocurrency[0].confirmation) > Number(invoiceData.invoice.confirmation) &&
																				` We are currently awaiting confirmation for the funds sent ( ${invoiceData.invoice.confirmation} / ${invoiceData.cryptocurrency[0].confirmation} )`}
																		</small>
																	</div>
																</div>
																<div className="row mt-3">
																	<div className="col">
																		<small className="text-primary">Amount expected to be paid</small>
																		<br />
																		<small className="text-primary">
																			{Number(invoiceData.invoice.expectedUSDAmount).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}{" "}
																			( {`${invoiceData.invoice.expectedCryptoAmount} ${invoiceData.invoice.currencyCode.toUpperCase()}`} )
																		</small>
																	</div>
																	<div className="col">
																		<small className="text-primary">Amount received</small>
																		<br />
																		<small className="text-danger">
																			{Number(invoiceData.invoice.usdAmountPaid).toLocaleString("en-US", {
																				style: "currency",
																				currency: "USD",
																			})}{" "}
																			( {`${invoiceData.invoice.cryptoAmountPaid} ${invoiceData.invoice.currencyCode.toUpperCase()}`} )
																		</small>
																	</div>
																</div>
															</React.Fragment>
														)}
													</div>
												</React.Fragment>
											) : (
												<React.Fragment>
													<span className="text-dark">Unexpected status code</span>
												</React.Fragment>
											))}
									</div>
								</div>
							</div>

							{invoiceData !== null && (
								<div className="row justify-content-center mb-5 pb-5">
									<i className="fe fe-lock mr-1" /> Secured with{" "}
									<a href={appLink.home} className="ml-1" rel="noreferrer noreferrer" target="_blank">
										{" "}
										Kollet
									</a>
								</div>
							)}
						</React.Fragment>
					) : (
						<FormLoading />
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default ComponentBody;
