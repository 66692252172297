/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import React from "react";

const AuthWrapper = ({ appLink, ...props }) => {
	return (
		<React.Fragment>
			<div
				className="d-flex align-items-center bg-auth border-top border-top-2 border-primary h-100"
				/*style={{
					background: "url('/assets/img/illustrations/AuthBackground.png')",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					minHeight: "100vh",
					height: "100vh",
				}}*/
			>
				<div className="container mt-5">
					<div className="row justify-content-center">{props.children}</div>
					{/* / .row */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default AuthWrapper;
