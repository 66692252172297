/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import FormLoading from "../loader/form_loading";
import { SendPostRequest } from "../util/request";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UnknownUser from "../../unknownUser.png";

const ComponentBody = ({ appLink, ...props }) => {
	const [step, setStep] = useState(1);
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const [, setMerchantId] = useState(null);
	const [textCopied, setTextCopied] = useState(false);
	const [merchantData, setMerchantData] = useState(null);
	const [merchantSelectedCoin, setMerchantSelectedCoin] = useState(null);

	const loadMerchantInformation = (username) => {
		setLoaderShow(true);

		SendPostRequest(appLink.merchantProfile, {
			username,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setMerchantData(response.data);
					document.title = response.data.merchant.businessName !== null ? `Pay ${response.data.merchant.businessName}` : "Pay Merchant";
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to Kollet. Check Internet");
			});
	};
	useEffect(() => {
		try {
			let m = window.location.pathname.split("/")[1];
			setMerchantId(m);
			loadMerchantInformation(m);
		} catch (error) {
			window.location.assign(appLink.appRoot);
		}
	}, []);

	return (
		<React.Fragment>
			<div className="main-content">
				<div className="container-lg container-fluid">
					{!loaderShow ? (
						<React.Fragment>
							<div className="row justify-content-center pt-5">
								<div className="col-12 col-lg-7 col-xl-7 mt-5">
									<div className="card mt-5">
										{error !== null && (
											<div className="alert alert-danger alert-fill-danger alert-dismissible fade show m-5 mb-1" role="alert">
												{error}
												<button type="button" className="close" data-dismiss="alert" aria-label="Close">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										)}

										<div className="card-body text-center">
											<div>
												<img
													style={{ borderRadius: "50%", width: "150px", height: "150px" }}
													src={merchantData !== null ? merchantData.merchant.businessPhotoURL : UnknownUser}
													alt={merchantData !== null && merchantData.merchant.businessName}
												/>
											</div>

											<h2 className="card-title mt-3">
												<a className="text-primary" href={merchantData !== null ? merchantData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
													{merchantData !== null && merchantData.merchant.businessName}
												</a>
											</h2>
											<p className="card-text">
												<small className="">{merchantData !== null && merchantData.merchant.businessDescription}</small>
											</p>

											{merchantData !== null && (
												<div className="row align-items-center justify-content-between">
													<div className="col">
														<small>Email: </small>
														<a href={`mailto:${merchantData !== null && merchantData.merchant.email}`}>{merchantData !== null && merchantData.merchant.email}</a>
													</div>

													<div className="col">
														<small>Website: </small>
														<a href={merchantData.merchant.businessWebsite !== null ? merchantData.merchant.businessWebsite : "#"} target="_blank" rel="noreferrer noopener">
															{merchantData.merchant.businessWebsite}
														</a>
													</div>
												</div>
											)}
										</div>
										{/* Footer */}
										<div className="card-footer card-footer-boxed">
											<div className="row col-12">{merchantData !== null && <small>Select a cryptocurrency to pay {merchantData.merchant.businessName} in.</small>}</div>
											{step === 1 &&
												merchantData !== null &&
												merchantData.addresses.map((addressData, index) => (
													<React.Fragment key={index}>
														<div key={index} className="mt-3 row align-items-center justify-content-between">
															<div className="col-auto">
																<img src={addressData.currencyImage} className="mr-2" alt="crypto" width={20} height={20} /> {addressData.currencyName}
															</div>
															<div className="col-auto">
																{/* Link */}
																<button
																	onClick={(e) => {
																		e.preventDefault();
																		setTextCopied(false);
																		setMerchantSelectedCoin(addressData);
																		setStep(2);
																	}}
																	className="btn btn-sm btn-primary"
																>
																	Pay with {addressData.currencyName}
																</button>
															</div>
														</div>
													</React.Fragment>
												))}

											{step === 2 && (
												<React.Fragment>
													<div className="mt-3 row mb-3">
														<div className="col text-center">
															{merchantSelectedCoin === null ? "Unexpected error. Refresh page" : <QRCode value={merchantSelectedCoin.walletAddress} size={128} />}

															<br />
															<br />
															<small>Scan the QR code with your payment app to get {merchantData !== null && merchantData.merchant.businessName}'s payment address</small>
														</div>
														<div className="col">
															<div className="row">
																<span className="w-100">{merchantSelectedCoin === null ? "Unexpected error. Refresh page" : merchantSelectedCoin.walletAddress}</span>
																<br />
																<CopyToClipboard text={merchantSelectedCoin === null ? "Unexpected error. Refresh page" : merchantSelectedCoin.walletAddress} onCopy={(e) => setTextCopied(true)}>
																	<span
																		className="text-primary"
																		style={{ cursor: "pointer" }}
																		onClick={(e) => {
																			e.preventDefault();
																		}}
																	>
																		Copy payment address
																	</span>
																</CopyToClipboard>{" "}
																{textCopied && (
																	<span className="text-success ">
																		<i className="fe fe-check ml-2" />
																		copied
																	</span>
																)}
																<span className="mt-3">This is a valid {merchantSelectedCoin.currencyName} address. Funds can be sent from any wallet or exchange service.</span>
															</div>
															<div className="row mt-4">
																<button
																	onClick={(e) => {
																		setMerchantSelectedCoin(null);
																		setStep(1);
																		//cancelPayment();
																	}}
																	className="btn btn-sm btn-danger "
																>
																	Go back
																</button>
															</div>
														</div>
													</div>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>

							{merchantData !== null && (
								<div className="row justify-content-center mb-5 pb-5">
									<i className="fe fe-lock mr-1" /> Secured with{" "}
									<a href={appLink.home} className="ml-1" rel="noreferrer noreferrer" target="_blank">
										{" "}
										Kollet
									</a>
								</div>
							)}
						</React.Fragment>
					) : (
						<FormLoading />
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default ComponentBody;
